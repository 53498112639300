import apiRequest from '@helpers/apiRequest';

const getPayments = () => {
    return apiRequest('GET', `Payments/List`);
};

const getPaymentDetails = idPayment => {
  return apiRequest('GET', `Payments/Details/${idPayment}`);
};

const updatePayment = data => {
  const idPayment = data.idPayment;
  return apiRequest('PUT', `Payments/Update/${idPayment}`, data);
};

const newPayment = data => {
  return apiRequest('POST', 'Payments/New', data);
};

const deletePayment = data => {
  const idPayment = data.idPayment;
  return apiRequest('DELETE', `Payments/Delete/${idPayment}`);
};
const servicePayments = {
  getPayments,
  getPaymentDetails,
  updatePayment,
  newPayment,
  deletePayment,
};

export default servicePayments;
