import apiRequest from '@helpers/apiRequest';

const getConfigs = () => {
    return apiRequest('GET', `Configs/List`);
};

const getConfigDetails = idConfig => {
  return apiRequest('GET', `Configs/Details/${idConfig}`);
};

const updateConfig = data => {
  const idConfig = data.idConfig;
  return apiRequest('PUT', `Configs/Update/${idConfig}`, data);
};

const newConfig = data => {
  return apiRequest('POST', 'Configs/New', data);
};

const deleteConfig = data => {
  const idConfig = data.idConfig;
  return apiRequest('DELETE', `Configs/Delete/${idConfig}`);
};
const serviceConfigs = {
  getConfigs,
  getConfigDetails,
  updateConfig,
  newConfig,
  deleteConfig,
};

export default serviceConfigs;
