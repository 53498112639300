import React from 'react';
import AddButton from '@components/AddButton';
import DynamicTable from '@components/DynamicTable';
import HeaderTitle from '@components/HeaderTitle';
import { IconButton } from '@mui/material';
import servicePayments from '@services/servicePayments';
import { useCommonStyles } from '@styles/common.style';
import { useNavigate } from 'react-router-dom';

import { Launch } from '@mui/icons-material';

const Payments = () => {
  const classes = useCommonStyles();
  const navigate = useNavigate();
  

  const COLUMNS = [
    {
      accessor: 'idPayment',
      Header: 'Nº',
      filterable: false,
      width: '5%',
    },
    {
      accessor: 'paymentName',
      Header: 'Descripción de la forma de pago',
    },
      {
          accessor: '',
          Header: 'Info',
          filterable: false,
          hideHeader: true,
          width: '5%',
          Cell: ({ row: { values } }) => (
              <IconButton
                  aria-label='info'
                  color='primary'
                  style={{
                      padding: '0px',
                  }}
                  onClick={() => {
                      handleShowPaymentDetail(values.idPayment);
                  }}>
                  <Launch fontSize='inherit' />
              </IconButton>
          ),
      },
  ];

  const handleShowPaymentDetail = idPayment => {
    servicePayments.getPaymentDetails(idPayment).then(response => {
      if (!response.error) {
        navigate('/paymentDetail', {
          state: { data: response.data, type: 'UPDATE' },
        });
      }
    });
  };

  const handleNewPayment = () => {
    navigate('/paymentDetail', { state: { data: {}, type: 'NEW' } });
  };
  return (
    <div className={classes.container}>
          <HeaderTitle
              title='Formas de pago'
              subtitle='Listado de formas de pago disponibles en los expedientes'>
              <AddButton text='Nueva forma de pago' handleOnclick={handleNewPayment} />
          </HeaderTitle>
      <DynamicTable
        columns={COLUMNS}
        endpoint='Payments/List'
        isGlobalSearch={true}
        isColumnsSearch={true}
        isOrdered={true}
      />
    </div>
  );
};

export default Payments;
