import React from 'react';
import { useStyles } from '@styles/menu.style';
import { Grid } from '@mui/material';
import menuItems from '@constants/menuItems';
import { useNavigate } from 'react-router-dom';
import MenuButton from '@components/MenuButton';


const MenuHorizontal = () => {
  
  const classes = useStyles();

  const navigate = useNavigate();

  const handleClickItem = item => {
    navigate(item.url);
  };

  return (
    <Grid className={classes.constainerHorizontal}>
      {menuItems.map((menu, index) => (
        <MenuButton
          key={index}
          menuTitle={menu.menuTitle}
          items={menu.items}
          handleClick={handleClickItem}
        />
      ))}
    </Grid>
  );
};

export default MenuHorizontal;
