import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import React from 'react';
import { DebounceInput as Debounce } from 'react-debounce-input';
import { useStyles } from '@styles/table.style';

const Search = ({ handleOnchange, name, global = false }) => {
  const classes = useStyles({ global });
  return (
    <div className={classes.searchContainer}>
      <Debounce
        className={classes.search}
        minLength={0}
        debounceTimeout={500}
        id={name}
        name={name}
        type='text'
        onChange={handleOnchange}
      />
      <SearchRoundedIcon className={classes.searchIcon} color='primary' />
    </div>
  );
};

export default Search;
