import React from 'react';
import AddButton from '@components/AddButton';
import DynamicTable from '@components/DynamicTable';
import HeaderTitle from '@components/HeaderTitle';
import { IconButton } from '@mui/material';
import serviceProviders from '@services/serviceProviders';
import { useCommonStyles } from '@styles/common.style';
import { useNavigate } from 'react-router-dom';

import { Launch } from '@mui/icons-material';

const Providers = () => {
  const classes = useCommonStyles();
  const navigate = useNavigate();
  

  const COLUMNS = [
    {
      accessor: 'idProvider',
      Header: 'Nº',
      filterable: false,
      width: '5%',
    },
    {
      accessor: 'providerName',
      Header: 'Nombre',
    },
    {
      accessor: 'providerSurname',
      Header: 'Apellidos',
      Cell: props => <p>{props.value}</p>,
    },
    { accessor: 'providerEmail', Header: 'E-Mail' },

      {
          accessor: '',
          Header: 'Info',
          filterable: false,
          hideHeader: true,
          width: '5%',
          Cell: ({ row: { values } }) => (
              <IconButton
                  aria-label='info'
                  color='primary'
                  style={{
                      padding: '0px',
                  }}
                  onClick={() => {
                      handleShowProviderDetail(values.idProvider);
                  }}>
                  <Launch fontSize='inherit' />
              </IconButton>
          ),
      },
  ];

  const handleShowProviderDetail = idProvider => {
    serviceProviders.getProviderDetails(idProvider).then(response => {
      if (!response.error) {
        navigate('/providerDetail', {
          state: { data: response.data, type: 'UPDATE' },
        });
      }
    });
  };

  const handleNewProvider = () => {
    navigate('/providerDetail', { state: { data: {}, type: 'NEW' } });
  };
  return (
    <div className={classes.container}>
          <HeaderTitle
              title='Proveedores'
              subtitle='Listado de proveedores'>
              <AddButton text='Nuevo proveedor' handleOnclick={handleNewProvider} />
          </HeaderTitle>
      <DynamicTable
        columns={COLUMNS}
        endpoint='Providers/List'
        isGlobalSearch={true}
        isColumnsSearch={true}
        isOrdered={true}
      />
    </div>
  );
};

export default Providers;
