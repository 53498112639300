import React, { useState, useEffect } from 'react';
import Avatar from '@components/Avatar';
import Dialog from '@components/Dialog';
import FormActions from '@components/FormActions';
import HeaderTitle from '@components/HeaderTitle';
import { useForm } from '@hooks/useForm';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Card,
  CardContent,
} from '@mui/material';
import serviceMailing from '@services/serviceMailing';
import serviceList from '@services/serviceList';
import { useCommonStyles } from '@styles/common.style';
import { useLocation, useNavigate } from 'react-router-dom';


const MailingDetail = () => {
  
  const navigate = useNavigate();
  const classes = useCommonStyles();
  const {
    state: { data: USER_DATA, type: TYPE },
  } = useLocation();

  const [openDialog, setOpenDialog] = useState(false);
  const { formData, setFormData, handleChange } = useForm(USER_DATA);

  const HEADER_TITLE =
      TYPE === 'NEW' ? 'Nueva plantilla de e-mail' : 'Editar plantilla de e-mail';
  const HEADER_SUBTITLE =
      TYPE === 'NEW' ? 'Crea una nueva plantilla de e-mail' : 'Puedes editar, eliminar y actualizar las plantilla de e-mail';

  const disabledSaveButton = USER_DATA === formData;
  const [roles, setRoles] = useState(null);

  useEffect(() => {
    serviceList.getRolesList().then(response => {
      if (!response.error) {
        setRoles(response.data);
      }
    });
  }, []);

  const handleSubmit = type => {
    switch (type) {
      case 'NEW':
        serviceMailing.newMailing(formData).then(handleResponse);
        break;
      case 'UPDATE':
        serviceMailing.updateMailing(formData).then(handleResponse);
        break;
      case 'DELETE':
        serviceMailing.deleteMailing(formData).then(handleResponse);
        break;
      default:
        break;
    }
  };

  const handleResponse = response => {
    if (!response.error) {
      navigate('/mailings');
    }
  };

  const handleChangeImage = e => {
    const image = e.base64.replace('data:' + e.type + ';base64,', '');
    setFormData(prevState => {
      return { ...prevState, mailingImage: image };
    });
  };

  return (
    <div className={classes.container}>
      <Dialog
        open={openDialog}
        hasCloseBtn={false}
        close={() => setOpenDialog(false)}
        title='¿Seguro que quieres eliminar la forma de pago?'
        subtitle='Si lo eliminas no podrás recuperarlo'
        height={200}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
                color='error'>
                Cancelar
              </Button>
            </Box>
            <Button variant='contained' onClick={() => handleSubmit('DELETE')}>
              Borrar
            </Button>
          </Box>
        }
      />

      <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
      <FormActions
          showDelete={false}
          show={TYPE === 'UPDATE'}
          handleDelete={() => setOpenDialog(true)}
          handleUndo={() => setFormData(USER_DATA)}
          handleSave={() => handleSubmit(TYPE)}
          disabledButton={disabledSaveButton}
        />
      </HeaderTitle>

      <Grid container spacing={4}>
              <Grid item xs={8} mt={2}>
              <TextField
                  label='Título del E-Mail'
                  name='mailingSubject'
                      value={formData.mailingSubject || ''}
                  size='small'
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  />
                  <TextField
                      mt={3}
                      label='Cuerpo del E-Mail'
                      name='mailingBody'
                      value={formData.mailingBody || ''}
                      size='small'
                      onChange={handleChange}
                      fullWidth
                      margin='normal'
                      multiline
                      rows={20}
                  />
              </Grid>
              <Grid item xs={4} mt={2}>
                  <Card style={{ marginTop: '15px', height: '540px', backgroundColor: 'lightgray'}}>
                      <CardContent>
                          <h3>Etiquetas dinámicas disponibles:</h3><br/><br/>
                          <strong>#idRequest#</strong> - Nº del expediente<br />
                          <strong>#clientName#</strong> - Nombre del cliente<br />
                          <strong>#clientCity#</strong> - Ciudad del cliente<br />
                      </CardContent>
                  </Card>
              </Grid>
      </Grid>
    </div>
  );
};

export default MailingDetail;
