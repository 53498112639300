import React from 'react';
import AddButton from '@components/AddButton';
import DynamicTable from '@components/DynamicTable';
import HeaderTitle from '@components/HeaderTitle';
import { IconButton } from '@mui/material';
import serviceMailing from '@services/serviceMailing';
import { useCommonStyles } from '@styles/common.style';
import { useNavigate } from 'react-router-dom';

import { Launch } from '@mui/icons-material';

const Mailing = () => {
  const classes = useCommonStyles();
  const navigate = useNavigate();
  

  const COLUMNS = [
    {
      accessor: 'idMailing',
      Header: 'Nº',
      filterable: false,
      width: '5%',
    },
    {
      accessor: 'mailingDesc',
      Header: 'Tipo de e-mail',
      }, {
          accessor: 'mailingSubject',
          Header: 'Título de e-mail',
      },
      {
          accessor: '',
          Header: 'Info',
          filterable: false,
          hideHeader: true,
          width: '5%',
          Cell: ({ row: { values } }) => (
              <IconButton
                  aria-label='info'
                  color='primary'
                  style={{
                      padding: '0px',
                  }}
                  onClick={() => {
                      handleShowMailingDetail(values.idMailing);
                  }}>
                  <Launch fontSize='inherit' />
              </IconButton>
          ),
      },
  ];

  const handleShowMailingDetail = idMailing => {
    serviceMailing.getMailingDetails(idMailing).then(response => {
      if (!response.error) {
        navigate('/mailingDetail', {
          state: { data: response.data, type: 'UPDATE' },
        });
      }
    });
  };

  const handleNewMailing = () => {
    navigate('/mailingDetail', { state: { data: {}, type: 'NEW' } });
  };
  return (
    <div className={classes.container}>
          <HeaderTitle
              title='Plantillas de e-mail'
              subtitle='Configuración de plantillas de e-mail'>
              
          </HeaderTitle>
      <DynamicTable
        columns={COLUMNS}
        endpoint='Mailing/List'
        isGlobalSearch={true}
        isColumnsSearch={true}
        isOrdered={true}
      />
    </div>
  );
};

export default Mailing;
