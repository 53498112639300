import { createTheme } from '@mui/material';

const primaryColor = {
    light: '#78c0cc',
    main: '#2d3e50',
    dark: '#33373a',
};

const secondaryColor = {
  light: '#ffffff',
  main: '#fabe3a',
  dark: '#282828',
};

const errorColor = {
  light: '#FF6347',
  main: '#da4f49',
  dark: '#D9391C',
};

const customTheme = createTheme({
  palette: {
    primary: {
      light: primaryColor.light,
      main: primaryColor.main,
      dark: primaryColor.dark,
    },
    secondary: {
      light: secondaryColor.light,
      main: secondaryColor.main,
      dark: secondaryColor.dark,
    },
    grey: {
      main: '#f1f1f1',
    },
    error: {
      main: errorColor.main,
    },
  },
});

export default customTheme;
