import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'es',
  lng: localStorage.getItem('lang'),
  resources: {
    zh: {
      translations: require('./locales/zh/translations.json'),
    },
    es: {
      translations: require('./locales/es/translations.json'),
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

export default i18n;
