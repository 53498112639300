import React from "react";
import { Grid, Hidden } from "@mui/material";
import MenuVertical from "@layout/MenuVertical";
import MenuHorizontal from "@layout/MenuHorizontal";

const Menu = ({ showHorizontalMenu, handleShowMenu, showMenu }) => {
  return (
    <Grid container>
      {showHorizontalMenu ? (
        <Hidden mdDown>
          <Grid item xs={12}>
            <MenuHorizontal />
          </Grid>
        </Hidden>
      ) : (
        <Hidden mdDown>
          <Grid item xs={12}>
            <MenuVertical handleShowMenu={handleShowMenu} showMenu={showMenu} />
          </Grid>
        </Hidden>
      )}
      <Hidden mdUp>
        <Grid item xs={12}>
          <MenuVertical handleShowMenu={handleShowMenu} showMenu={showMenu} />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default Menu;
