import React, { Fragment, useEffect, useState } from 'react';
import Search from '@components/Search';
import apiRequest from '@helpers/apiRequest';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useCommonStyles } from '@styles/common.style';
import { useStyles } from '@styles/table.style';
import { useExpanded, usePagination, useSortBy, useTable } from 'react-table';

import Loading from './Loading';
    

const DynamicTable = ({
    columns,
    isGlobalSearch = false,
    isColumnsSearch = false,
    isOrdered = false,
    isPaginated = true,
    target="",
    customFilter = [],
    customOrder = [],
    customPageSize = 10,
    endpoint,
    reload = 0,
  ...props
}) => {
  
  const [isMounted, setIsMounted] = useState(true);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [firstLoading, setFirstLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [controlledPageCount, setControlledPageCount] = useState(0);

  const [globalSearch, setGlobalSearch] = useState('');
  const [prevGlobalSearch, setPrevGlobalSearch] = useState('');

    const [prevColumnSearch, setPrevColumnSearch] = useState(customFilter);
    const [columnSearch, setColumnSearch] = useState(customFilter);

    const [order, setOrder] = useState(customOrder);
  const classesCommon = useCommonStyles();
  const classes = useStyles();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        hiddenColumns: columns
          .filter(column => column.show==false)
          .map(column => column.accessor),
      },
      manualPagination: true,
      manualSortBy: false,
      autoResetPage: false,
      pageCount: controlledPageCount,
    },
    useSortBy,
    useExpanded,
    usePagination,
  );

  const fetchData = ({
    pageSize,
    pageIndex,
    globalSearch,
    columnSearch,
    order,
    endpoint,
  }) => {
    setLoading(true);
    if (prevGlobalSearch !== globalSearch) {
      gotoPage(0);
      setPrevGlobalSearch(globalSearch);
    }
    if (prevColumnSearch !== columnSearch) {
      gotoPage(0);
      setPrevColumnSearch(columnSearch);
    }
    apiRequest('post', endpoint, {
      pageSize: pageSize,
      pageNumber: pageIndex,
      globalSearch: globalSearch,
      search: columnSearch,
      order: order,
    }).then(response => {
      if (!response.error) {
        setData(response.data);
        setTotalRecords(response.pagination.records);
        setControlledPageCount(response.pagination.pages);
          setLoading(false);
          setFirstLoading(false)
      }
    });
  };

  const handleGlobalSearch = search => {
    setGlobalSearch(search);
  };

  const handleColumnSearch = event => {
    var searchField = event.target.name;
    var searchValue = event.target.value;
    var searchArray = columnSearch;
    searchArray = searchArray.filter(
      filter => filter.SearchField !== searchField,
    );
    if (searchValue) {
      searchArray.push({
        SearchField: searchField,
        SearchValue: searchValue,
        SearchMode: 'Contains',
      });
    }
    setColumnSearch(searchArray);
  };

  const handleOrder = (orderField, orderMode) => {
    var orderArray = order;
    orderArray = orderArray.filter(filter => filter.orderField !== orderField);
    if (orderMode) {
      orderArray.unshift({
        orderField: orderField,
        orderAsc: orderMode === 'asc' ? true : false,
      });
    }
    setOrder(orderArray);
  };

  useEffect(() => {
    fetchData &&
      fetchData({
        pageIndex,
        pageSize,
        globalSearch,
        columnSearch,
        order,
        endpoint,
      });
  }, [pageIndex, pageSize, globalSearch, columnSearch, order, endpoint, reload]);

    useEffect(() => {
        return () => {
            setIsMounted(false);
        }
    }, []);

  return (
    <Fragment>
      {isGlobalSearch && (
        <Grid container my={1} className={classes.globalSearchContainer}>
          <Search
            global
            name='searchTerm'
            handleOnchange={e => handleGlobalSearch(e.target.value)}
          />
        </Grid>
      )}
      <div className={classes.container}>
        <Table {...getTableProps()}>
          <TableHead className={classesCommon.bgPrimary}>
            {headerGroups.map((headerGroup, i) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map(column => {
                  return (
                    /* TÍTULOS TABLA */
                    <TableCell
                      {...column.getHeaderProps()}
                      style={{
                        width: column.width,
                        padding: '10px 16px',
                      }}>
                      <Box display='flex' alignItems='center'>
                        {!column.hideHeader && (
                          <Typography
                            color='secondary.light'
                            variant='button'
                            fontWeight='bold'
                            mr={1}>
                            {column.render('Header')}
                          </Typography>
                        )}
                        {isOrdered && !column.hideHeader && (
                          <>
                            {order.filter(
                              filter => filter.orderField === column.id,
                            ).length > 0 ? (
                              order.filter(
                                filter => filter.orderField === column.id,
                              )[0].orderAsc ? (
                                <IconButton
                                  aria-label='delete'
                                  size='small'
                                  color='grey'
                                  onClick={() =>
                                    handleOrder(column.id, 'desc')
                                  }>
                                  <KeyboardArrowDownIcon fontSize='inherit' />
                                </IconButton>
                              ) : (
                                <IconButton
                                  size='small'
                                  color='grey'
                                  onClick={() => handleOrder(column.id, null)}>
                                  <KeyboardArrowUpIcon fontSize='inherit' />
                                </IconButton>
                              )
                            ) : (
                              <IconButton
                                size='small'
                                color='grey'
                                onClick={() => handleOrder(column.id, 'asc')}>
                                <UnfoldMoreIcon fontSize='inherit' />
                              </IconButton>
                            )}
                          </>
                        )}
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          {isColumnsSearch && (
                      <TableBody className={classesCommon.bgDarkGrey}>
              {headerGroups.map((headerGroup, i) => (
                <TableRow key={i}>
                  {headerGroup.headers.map(column => {
                    /* BUSCADORES */
                    return (
                      <TableCell
                        {...column.getHeaderProps()}
                        style={{
                          width: column.width,
                          padding: '10px 16px',
                        }}>
                        {column.filterable == null && (
                          <Search
                            name={column.id}
                            handleOnchange={e => handleColumnSearch(e)}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
                  )}
                  {/* LOS DATOS */}
          <TableBody {...getTableBodyProps()} className={classesCommon.bgGrey}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                  <TableRow
                  key={i}
                  style={{
                    filter: loading && 'blur(2px)',
                  }}
                  {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <TableCell
                        key={i}
                        padding='none'
                        {...cell.getCellProps()}
                        style={{
                          width: cell.column.width,
                          padding: '10px 16px',
                        }}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
                      </TableBody>
              </Table>
              {firstLoading && <LinearProgress color="inherit" />}
      </div>
      {isPaginated && (
        <Grid container className={classes.footer} py={2}>
          <Grid item className={classes.footerResult}>
            <Typography mr={2} color='primary'>
              {totalRecords} registros
            </Typography>
            <Typography variant='subtitle2'>
              Mostrando desde {pageIndex * pageSize + 1}{' '}
              a {pageIndex * pageSize + pageSize}
            </Typography>
          </Grid>
          <Grid item className={classes.footerPagination}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => previousPage()}
              disabled={!canPreviousPage}>
              ANTERIOR
            </Button>

            <Typography
              mx={2}
              sx={{
                fontSize: {
                  xs: '12px',
                  md: '14px',
                },
              }}>
              Página {pageIndex + 1} de {pageOptions.length}
            </Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={() => nextPage()}
              disabled={!canNextPage}>
              SIGUIENTE
            </Button>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default DynamicTable;
