import React from 'react';
import AddButton from '@components/AddButton';
import DynamicTable from '@components/DynamicTable';
import HeaderTitle from '@components/HeaderTitle';
import { IconButton } from '@mui/material';
import serviceConfigs from '@services/serviceConfigs';
import { useCommonStyles } from '@styles/common.style';
import { useNavigate } from 'react-router-dom';

import { Launch } from '@mui/icons-material';

const Configs = () => {
  const classes = useCommonStyles();
  const navigate = useNavigate();
  

  const COLUMNS = [
    {
      accessor: 'idConfig',
      Header: 'Nº',
      filterable: false,
      width: '5%',
    },
    {
      accessor: 'configDesc',
      Header: 'Tipo de configuración',
      },
      {
          accessor: 'configKey',
          Header: 'Código de configuración',
      },
      {
          accessor: '',
          Header: 'Info',
          filterable: false,
          hideHeader: true,
          width: '5%',
          Cell: ({ row: { values } }) => (
              <IconButton
                  aria-label='info'
                  color='primary'
                  style={{
                      padding: '0px',
                  }}
                  onClick={() => {
                      handleShowConfigDetail(values.idConfig);
                  }}>
                  <Launch fontSize='inherit' />
              </IconButton>
          ),
      },
  ];

  const handleShowConfigDetail = idConfig => {
    serviceConfigs.getConfigDetails(idConfig).then(response => {
      if (!response.error) {
        navigate('/configDetail', {
          state: { data: response.data, type: 'UPDATE' },
        });
      }
    });
  };

  const handleNewConfig = () => {
    navigate('/configDetail', { state: { data: {}, type: 'NEW' } });
  };
  return (
    <div className={classes.container}>
          <HeaderTitle
              title='Configuración de la aplicación'
              subtitle='Listado de valores de configuración'>
          </HeaderTitle>
      <DynamicTable
        columns={COLUMNS}
        endpoint='Configs/List'
        isGlobalSearch={true}
        isColumnsSearch={true}
        isOrdered={true}
      />
    </div>
  );
};

export default Configs;
