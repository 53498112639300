import React, { useState } from 'react';
import Logo from '@assets/images/logoLogin.png';
import { useForm } from '@hooks/useForm';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import serviceLogin from '@services/serviceLogin';
import { useStyles } from '@styles/login.style';

import { useNavigate } from 'react-router-dom';

const LostPassword = () => {
  const navigate = useNavigate();
  
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const { userEmail, formData, handleChange } = useForm({
    userEmail: '',
  });

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    serviceLogin.lostPassword(formData).then(response => {
      if (!response.error) {
        navigate('/newPassword', { replace: true });
      }
      setLoading(false);
    });
  };

  return (
    <div className={classes.login}>
      <form onSubmit={handleSubmit} className={classes.card}>
        <div className={classes.header}>
            <img src={Logo} alt='logo' className={classes.logo} ></img>
        </div>

        <div>
          <Typography color='secondary' align='left' mt={3} fontSize={14}>
            Introduce tu e-mail
          </Typography>
          <TextField
            label="Dirección de e-mail"
            variant='filled'
            name='userEmail'
            type='email'
            fullWidth
            value={userEmail}
            onChange={handleChange}
            margin='dense'
            size='small'
          />
        </div>
        <Box mt={3}>
          <Button variant='contained' fullWidth type='submit' size='large'>
            <Box display='flex' alignItems='center'>
              {loading && <CircularProgress size={25} color='grey' />}
              SIGUENTE PASO
            </Box>
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default LostPassword;
