import React, { useState, useEffect } from 'react';
import { GoogleButton } from "react-google-oauth2";
import serviceConfigs from '@services/serviceConfigs';

const Google = () => {

    const [options, setOptions] = useState({
        clientId: null,
        redirectUri: "http://localhost:3000/google",
        scopes: ["https://www.googleapis.com/auth/drive","https://www.googleapis.com/auth/spreadsheets"],
        includeGrantedScopes: true,
        accessType: "offline",
    });

    useEffect(() => {
            serviceConfigs.getConfigDetails(1).then(response => {
                if (!response.error) {
                    setOptions(prevState => {
                        return { ...prevState, clientId: response.data.configValue }
                    });
                }
            });
    }, []);

  return (
      <div>
          {options.clientId!==null &&
              <GoogleButton options={options}>Autorizar ahora</GoogleButton>
          }
        </div>
  );
};

export default Google;
