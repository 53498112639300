import React from 'react';
import AddButton from '@components/AddButton';
import DynamicTable from '@components/DynamicTable';
import HeaderTitle from '@components/HeaderTitle';
import { IconButton } from '@mui/material';
import serviceUsers from '@services/serviceUsers';
import { useCommonStyles } from '@styles/common.style';
import { useNavigate } from 'react-router-dom';

import { Launch } from '@mui/icons-material';

const Users = () => {
  const classes = useCommonStyles();
  const navigate = useNavigate();
  

  const COLUMNS = [
    {
      accessor: 'idUser',
      Header: 'Nº',
      filterable: false,
      width: '5%',
    },
    {
      accessor: 'userName',
      Header: 'Nombre',
    },
    {
      accessor: 'userSurname',
      Header: 'Apellidos',
      Cell: props => <p>{props.value}</p>,
    },
    { accessor: 'userEmail', Header: 'E-Mail' },

      {
          accessor: '',
          Header: 'Info',
          filterable: false,
          hideHeader: true,
          width: '5%',
          Cell: ({ row: { values } }) => (
              <IconButton
                  aria-label='info'
                  color='primary'
                  style={{
                      padding: '0px',
                  }}
                  onClick={() => {
                      handleShowUserDetail(values.idUser);
                  }}>
                  <Launch fontSize='inherit' />
              </IconButton>
          ),
      },
  ];

  const handleShowUserDetail = idUser => {
    serviceUsers.getUserDetails(idUser).then(response => {
      if (!response.error) {
        navigate('/userDetail', {
          state: { data: response.data, type: 'UPDATE' },
        });
      }
    });
  };

  const handleNewUser = () => {
    navigate('/userDetail', { state: { data: {}, type: 'NEW' } });
  };
  return (
    <div className={classes.container}>
      <HeaderTitle
        title='Usuarios de la aplicación'
        subtitle='Listado de usuarios con acceso a la aplicación de gestión'>
        <AddButton text='Nuevo usuario' handleOnclick={handleNewUser} />
      </HeaderTitle>
      <DynamicTable
        columns={COLUMNS}
        endpoint='Users/List'
        isGlobalSearch={true}
        isColumnsSearch={true}
        isOrdered={true}
      />
    </div>
  );
};

export default Users;
