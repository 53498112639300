import React from 'react';
import { Box, Button } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import FileBase64 from 'react-file-base64';
import { useStyles } from '@styles/avatar.style';

const Avatar = props => {
  const classes = useStyles(props);

  return (
    <Box>
          <Box className={classes.containerImage} style={{border:'1px solid lightgray'}}>
        {props.image && (
          <img
            src={`data:image/png;base64,${props.image}`}
            className={classes.image}
            alt='avatar'
          />
        )}

        <Box
          className={
            props.image ? classes.layoutButton : classes.containerNoImage
          }>
          <Button
            variant='raised'
            component='span'
            aria-label='upload picture'
            onClick={() => {
              document.querySelector('#upload-button>input').click();
            }}>
            <PhotoCamera color='primary' />
          </Button>
        </Box>
      </Box>
      <div id='upload-button' style={{ display: 'none' }}>
        <FileBase64 multiple={false} onDone={props.handleChange} />
      </div>
    </Box>
  );
};

export default Avatar;
