import apiRequest from '@helpers/apiRequest';

const getOrigins = () => {
    return apiRequest('GET', `Origins/List`);
};

const getOriginDetails = idOrigin => {
  return apiRequest('GET', `Origins/Details/${idOrigin}`);
};

const updateOrigin = data => {
  const idOrigin = data.idOrigin;
  return apiRequest('PUT', `Origins/Update/${idOrigin}`, data);
};

const newOrigin = data => {
  return apiRequest('POST', 'Origins/New', data);
};

const deleteOrigin = data => {
  const idOrigin = data.idOrigin;
  return apiRequest('DELETE', `Origins/Delete/${idOrigin}`);
};
const serviceOrigins = {
  getOrigins,
  getOriginDetails,
  updateOrigin,
  newOrigin,
  deleteOrigin,
};

export default serviceOrigins;
