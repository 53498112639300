import { useState } from 'react';

export const useForm = initialState => {
  const [state, setState] = useState(initialState);

  const handleChange = e => {
    setState(prevState => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  return {
    ...state,
    formData: state,
    setFormData: setState,
    handleChange,
  };
};
