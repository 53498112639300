import apiRequest from '@helpers/apiRequest';

const getUserDetails = idUser => {
  return apiRequest('GET', `Users/Details/${idUser}`);
};

const getUserNames = () => {
    return apiRequest('GET', `Users/ListNames`);
};

const updateUser = data => {
  const idUser = data.idUser;
  return apiRequest('PUT', `Users/Update/${idUser}`, data);
};

const newUser = data => {
  return apiRequest('POST', 'Users/New', data);
};

const deleteUser = data => {
  const idUser = data.idUser;
  return apiRequest('DELETE', `Users/Delete/${idUser}`);
};
const serviceUsers = {
    getUserNames,
  getUserDetails,
  updateUser,
  newUser,
  deleteUser,
};

export default serviceUsers;
