import React, { useState, useEffect } from 'react';
import AddButton from '@components/AddButton';
import DynamicTable from '@components/DynamicTable';
import HeaderTitle from '@components/HeaderTitle';
import Moment from 'react-moment';
import { IconButton } from '@mui/material';
import serviceRequests from '@services/serviceRequests';
import { useCommonStyles } from '@styles/common.style';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Launch, WindowSharp } from '@mui/icons-material';

const Requests = () => {




    const classes = useCommonStyles();
    const navigate = useNavigate();
    const params = useParams();
    
    const [reloadKey, setReloadKey] = useState(Math.random());
    const [requestStatusName, setRequestStatusName] = useState("");
    const [tableFilter, setTableFilter] = useState([]);
    const [tableOrder, setTableOrder] = useState([]);
    const userSession = JSON.parse(localStorage.getItem('userSession'));
    useEffect(async () => {
        var grant = !isNaN(params.idRequestGrantStatus);
        await serviceRequests.getRequestsStatus().then(response => {
            if (!response.error) {
                if (!grant) {
                    var status = response.data;
                    setRequestStatusName(status.find(x => x.idRequestStatus == params.idRequestStatus).requestStatusName);
                } else {
                    setRequestStatusName(params.idRequestGrantStatus == 1 ? "Pte. tramitar subvención" : params.idRequestGrantStatus == 2 ? "Esperando respuesta subvención" : "Subvención completada");
                }
            }
        });
        setTableFilter([
            {
                SearchField: grant ? "IdRequestGrantStatus" : "IdRequestStatus",
                SearchValue: grant ? params.idRequestGrantStatus : params.idRequestStatus,
                SearchMode: 'Equalto'
            }
        ]);
        setTableOrder([
            {
                OrderField: "IdRequest",
                OrderAsc: false,
            }
        ]);
        setReloadKey(Math.random());
    }, [params.idRequestStatus, params.idRequestGrantStatus]);


    const COLUMNS = [
        {
            accessor: 'idRequest',
            Header: 'Nº Exp.',
            filterable: false,
            width: '8%',
        },
        {
            accessor: 'requestCreated',
            Header: 'Fecha',
            Cell: ({ row: { values } }) => (
                <Moment format="DD/MM/YYYY - HH:mm">
                    {values.requestCreated}
                </Moment>
            ),
        },
        {
            accessor: 'clientName',
            Header: 'Nombre',
        },
        {
            accessor: 'clientCity',
            Header: 'Población',
        },
        {
            accessor: 'requestAmount',
            Header: 'Importe',
            Cell: ({ row: { values } }) => (
                <div>
                    {values.requestAmount.toFixed(2).replace(",","").replace(".",",")+"€"}
                </div>
            ),
        },
        {
            accessor: '',
            Header: 'Info',
            filterable: false,
            hideHeader: true,
            width: '5%',
            Cell: ({ row: { values } }) => (
                <IconButton
                    aria-label='info'
                    color='primary'
                    style={{
                        padding: '0px',
                    }}
                    onClick={() => {
                        handleShowRequestDetail(values.idRequest);
                    }}>
                    <Launch fontSize='inherit' />
                </IconButton>
            ),
        },
    ];

    const handleShowRequestDetail = idRequest => {
        serviceRequests.getRequestDetails(idRequest).then(response => {
            if (!response.error) {
                navigate(`/requestDetail`, {
                    state: { data: response.data, type: 'UPDATE' },
                });
            }
        });
    };

    const handleNewRequest = () => {
            navigate('/requestDetail', { state: { data: { requestQuotation: [] }, type: 'NEW' } });
    };


    return (
        <div className={classes.container}>
            <HeaderTitle
                title={"Expedientes en estado: " + requestStatusName}
                subtitle="Puede ver, editar y añadir nuevos expedientes">
                {
                    userSession?.idRole!==2 ?
                <AddButton
                    text="Crear nuevo"
                    handleOnclick={handleNewRequest}
                />:
                <a href={"https://gestion.ahorrosolar.net/commercial/"+userSession.idUser}>Nueva oportunidad</a>
                }
            </HeaderTitle>
            <DynamicTable
                key={reloadKey}
                columns={COLUMNS}
                customFilter={tableFilter}
                customOrder={tableOrder}
                endpoint='Requests/List'
                isGlobalSearch={true}
                isColumnsSearch={true}
                isOrdered={true}
            />
        </div>
    );
};

export default Requests;
