import React, { useState, useEffect } from 'react';
import Avatar from '@components/Avatar';
import Dialog from '@components/Dialog';
import FormActions from '@components/FormActions';
import HeaderTitle from '@components/HeaderTitle';
import { useForm } from '@hooks/useForm';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from '@mui/material';
import serviceProviders from '@services/serviceProviders';
import serviceList from '@services/serviceList';
import { useCommonStyles } from '@styles/common.style';
import { useLocation, useNavigate } from 'react-router-dom';


const ProviderDetail = () => {
  
  const navigate = useNavigate();
  const classes = useCommonStyles();
  const {
    state: { data: USER_DATA, type: TYPE },
  } = useLocation();

  const [openDialog, setOpenDialog] = useState(false);
  const { formData, setFormData, handleChange } = useForm(USER_DATA);

  const HEADER_TITLE =
    TYPE === 'NEW' ? 'Nuevo proveedor' : 'Editar proveedor';
  const HEADER_SUBTITLE =
      TYPE === 'NEW' ? 'Crea un nuevo proveedor al que asignar partidas' : 'Puedes editar, eliminar y actualizar los datos del proveedor';

  const disabledSaveButton = USER_DATA === formData;
  const [roles, setRoles] = useState(null);

  useEffect(() => {
    serviceList.getRolesList().then(response => {
      if (!response.error) {
        setRoles(response.data);
      }
    });
  }, []);

  const handleSubmit = type => {
    switch (type) {
      case 'NEW':
        serviceProviders.newProvider(formData).then(handleResponse);
        break;
      case 'UPDATE':
        serviceProviders.updateProvider(formData).then(handleResponse);
        break;
      case 'DELETE':
        serviceProviders.deleteProvider(formData).then(handleResponse);
        break;
      default:
        break;
    }
  };

  const handleResponse = response => {
    if (!response.error) {
      navigate('/providers');
    }
  };

  const handleChangeImage = e => {
    const image = e.base64.replace('data:' + e.type + ';base64,', '');
    setFormData(prevState => {
      return { ...prevState, providerImage: image };
    });
  };

  return (
    <div className={classes.container}>
      <Dialog
        open={openDialog}
        hasCloseBtn={false}
        close={() => setOpenDialog(false)}
        title='¿Seguro que quieres eliminar el proveedor?'
        subtitle='Si lo eliminas no podrás recuperarlo'
        height={200}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
                color='error'>
                Cancelar
              </Button>
            </Box>
            <Button variant='contained' onClick={() => handleSubmit('DELETE')}>
              Borrar
            </Button>
          </Box>
        }
      />

      <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
        <FormActions
          show={TYPE === 'UPDATE'}
          handleDelete={() => setOpenDialog(true)}
          handleUndo={() => setFormData(USER_DATA)}
          handleSave={() => handleSubmit(TYPE)}
          disabledButton={disabledSaveButton}
        />
      </HeaderTitle>

      <Grid container spacing={2}>
              <Grid item lg={3} xs={12} sm={6} mt={2}>
          <Typography color='primary' variant='subtitle1' mb={2}>
            Imagen del proveedor
          </Typography>
          <Box mt={2}>
            <Avatar
              height={'100%'}
              handleChange={handleChangeImage}
              image={formData?.providerImage}
            />
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          xs={12}
                  sm={6}
                  mt={2}
        >
          <Typography color='primary' variant='subtitle1'>
            Nombre y contacto
          </Typography>
          <Box>
            <TextField
              label='Nombre del proveedor'
              name='providerName'
              value={formData.providerName || ''}
              size='small'
              onChange={handleChange}
              fullWidth
              margin='normal'
            />
            <TextField
              label='Apellidos del proveedor'
              name='providerSurname'
              value={formData.providerSurname || ''}
              size='small'
              onChange={handleChange}
              fullWidth
              margin='normal'
            />
            <TextField
              label='Dirección de e-mail'
              name='providerEmail'
              value={formData.providerEmail || ''}
              size='small'
              onChange={handleChange}
              fullWidth
              margin='normal'
            />
            <TextField
              label='Teléfono de contacto'
              name='providerPhone'
              value={formData.providerPhone || ''}
              size='small'
              onChange={handleChange}
              fullWidth
              margin='normal'
            />
            
          </Box>
              </Grid>
              <Grid
                  item
                  lg={4}
                  xs={12}
                  sm={6}
                  mt={2}
              >
                  <Typography color='primary' variant='subtitle1'>
                      Datos adicionales
                  </Typography>
                  <Box>
                      <TextField
                          label='Ciudad'
                          name='providerCity'
                          value={formData.providerCity || ''}
                          size='small'
                          onChange={handleChange}
                          fullWidth
                          margin='normal'
                      />
                      <TextField
                          label='Direccion'
                          name='providerAddress'
                          value={formData.providerAddress || ''}
                          size='small'
                          onChange={handleChange}
                          fullWidth
                          margin='normal'
                      />
                  </Box>
              </Grid>
      </Grid>
    </div>
  );
};

export default ProviderDetail;
