import React from 'react';
import { Box, Divider, Typography } from '@mui/material';

const HeaderTitle = ({ title, subtitle, children }) => {
  return (
    <Box
      sx={{
        padding: { xs: '16px 16px 0 16px', sm: 0 },
      }}>
      <Box display='flex' justifyContent='space-between' mb={2}>
        <Box>
          <Typography sx={{ typography: { xs: 'body1', sm: 'h5' } }}>
            {title}
          </Typography>
          <Typography variant='subtitle2' color='primary'>
            {subtitle}
          </Typography>
        </Box>

        <Box display='flex' alignItems='center'>
          {children}
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

export default HeaderTitle;
