import React, { createContext, useReducer, useEffect } from 'react';
import serviceLogin from '@services/serviceLogin';
import { authReducer } from './authReducer';
export const AuthContext = createContext({});

const authInitialState = {
  status: 'checking',
  user: null,
  userRoles: null,
  userAreas: null,
    token: null
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, authInitialState);

  useEffect(() => {
    const token = localStorage.getItem('loginToken');
    if (!token) return dispatch({ type: 'notAuthenticated' });
    serviceLogin
      .checkIsAuthenticated(token)
      .then(user => {
        dispatch({
          type: 'signUp',
          payload: { token, user: user.data.userSession },
        });
      })
      .catch(() => {
        logoutHandler();
      });
  }, []);

  const loginHandler = user => {
    localStorage.setItem('userSession', JSON.stringify(user.userSession));
    localStorage.setItem('loginToken', JSON.stringify(user.loginToken.token));
    dispatch({
      type: 'signUp',
      payload: { token: user.loginToken.token, user: user.userSession },
    });
  };

  const logoutHandler = () => {
    localStorage.removeItem('userSession');
    localStorage.removeItem('loginToken');
    dispatch({ type: 'logout' });
  };

  const contextValue = {
    ...state,
    authState: state,
    login: loginHandler,
    logout: logoutHandler,
  };
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
