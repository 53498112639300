import apiRequest from '@helpers/apiRequest';

const getMailing = () => {
    return apiRequest('GET', `Mailing/List`);
};

const getMailingDetails = idMailing => {
  return apiRequest('GET', `Mailing/Details/${idMailing}`);
};

const updateMailing = data => {
  const idMailing = data.idMailing;
  return apiRequest('PUT', `Mailing/Update/${idMailing}`, data);
};

const newMailing = data => {
  return apiRequest('POST', 'Mailing/New', data);
};

const deleteMailing = data => {
  const idMailing = data.idMailing;
  return apiRequest('DELETE', `Mailing/Delete/${idMailing}`);
};

const sendOffer = idRequest => {
    return apiRequest('POST', `Mailing/SendOffer/${idRequest}`);
};

const serviceMailing = {
    sendOffer,
  getMailing,
  getMailingDetails,
  updateMailing,
  newMailing,
  deleteMailing,
};

export default serviceMailing;
