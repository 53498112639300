import React, { Fragment } from 'react';
import MenuList from '@components/MenuList';
import menuItems from '@constants/menuItems';
import { Accordion, AccordionSummary, Grid, Hidden } from '@mui/material';
import { useStyles } from '@styles/menu.style';
import { useNavigate } from 'react-router-dom';


const MenuVertical = ({ showMenu, handleShowMenu }) => {
  
  const classes = useStyles();
  const navigate = useNavigate();
  const handleClickItem = item => {
      navigate(item.url);
      handleShowMenu(false);
  };

  const menuList = menuItems.map((menu, index) => (
    <MenuList
      key={index}
      menuTitle={menu.menuTitle}
      items={menu.items}
      handleClick={handleClickItem}
    />
  ));

  return (
    <Fragment>
      <Hidden mdDown>
        <Grid className={classes.containerVertical}>{menuList}</Grid>
      </Hidden>

      <Hidden mdUp>
        <Accordion
          expanded={showMenu}
          onChange={() => handleShowMenu(!showMenu)}
          square
          className={classes.containerVertical}>
          <AccordionSummary sx={{ display: 'none' }}></AccordionSummary>
          {menuList}
        </Accordion>
      </Hidden>
    </Fragment>
  );
};

export default MenuVertical;
