import React, { useState, useEffect } from 'react';
import Avatar from '@components/Avatar';
import Dialog from '@components/Dialog';
import FormActions from '@components/FormActions';
import HeaderTitle from '@components/HeaderTitle';
import { useForm } from '@hooks/useForm';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from '@mui/material';
import serviceOrigins from '@services/serviceOrigins';
import serviceList from '@services/serviceList';
import { useCommonStyles } from '@styles/common.style';
import { useLocation, useNavigate } from 'react-router-dom';


const OriginDetail = () => {
  
  const navigate = useNavigate();
  const classes = useCommonStyles();
  const {
    state: { data: USER_DATA, type: TYPE },
  } = useLocation();

  const [openDialog, setOpenDialog] = useState(false);
  const { formData, setFormData, handleChange } = useForm(USER_DATA);

  const HEADER_TITLE =
    TYPE === 'NEW' ? 'Nueva origen de leads' : 'Editar origen de leads';
  const HEADER_SUBTITLE =
      TYPE === 'NEW' ? 'Crea una nuevo origen de leads para asignar a los expedientes' : 'Puedes editar, eliminar y actualizar los orígenes';

  const disabledSaveButton = USER_DATA === formData;
  const [roles, setRoles] = useState(null);

  useEffect(() => {
    serviceList.getRolesList().then(response => {
      if (!response.error) {
        setRoles(response.data);
      }
    });
  }, []);

  const handleSubmit = type => {
    switch (type) {
      case 'NEW':
        serviceOrigins.newOrigin(formData).then(handleResponse);
        break;
      case 'UPDATE':
        serviceOrigins.updateOrigin(formData).then(handleResponse);
        break;
      case 'DELETE':
        serviceOrigins.deleteOrigin(formData).then(handleResponse);
        break;
      default:
        break;
    }
  };

  const handleResponse = response => {
    if (!response.error) {
      navigate('/origins');
    }
  };

  const handleChangeImage = e => {
    const image = e.base64.replace('data:' + e.type + ';base64,', '');
    setFormData(prevState => {
      return { ...prevState, originImage: image };
    });
  };

  return (
    <div className={classes.container}>
      <Dialog
        open={openDialog}
        hasCloseBtn={false}
        close={() => setOpenDialog(false)}
        title='¿Seguro que quieres eliminar el orígen de leads?'
        subtitle='Si lo eliminas no podrás recuperarlo'
        height={200}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
                color='error'>
                Cancelar
              </Button>
            </Box>
            <Button variant='contained' onClick={() => handleSubmit('DELETE')}>
              Borrar
            </Button>
          </Box>
        }
      />

      <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
        <FormActions
          show={TYPE === 'UPDATE'}
          handleDelete={() => setOpenDialog(true)}
          handleUndo={() => setFormData(USER_DATA)}
          handleSave={() => handleSubmit(TYPE)}
          disabledButton={disabledSaveButton}
        />
      </HeaderTitle>

      <Grid container spacing={2}>
              <Grid item xs={12}mt={2}>
              <TextField
                  label='Descripción del origen de leads'
                  name='originName'
                      value={formData.originName || ''}
                  size='small'
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
              />
        </Grid>
      </Grid>
    </div>
  );
};

export default OriginDetail;
