import { makeStyles } from '@mui/styles';
import Image from '@assets/images/background.png';
export const useStyles = makeStyles(theme => {
  return {
    login: {
      backgroundImage: `linear-gradient(90deg, rgba(250,170,15,0.6) 0%, rgba(60,60,60,0.9) 100%),url(${Image})`,
      backgroundSize: 'cover',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      },
      selectLanguage: {
          height: '48px'
      },
    card: {
      maxWidth: '450px',
      background: 'white',
      padding: '40px 50px',
      [theme.breakpoints.down('sm')]: {
        margin: '20px',
        padding: '40px 25px',
      },
      },
    header: {
      textAlign: 'center',
      },
    logo: {
          width: '100%',
      },
    title: {
      margin: '10px 0',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    image: {
      width: '75%',
      [theme.breakpoints.down('sm')]: {
        width: '240px',
      },
    },
  };
});
