import React from 'react';
import { Box, Button, Hidden, Tooltip } from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { useStyles } from '@styles/form.style';


const FormActions = ({
    show,
    showDelete=true,
  handleDelete,
  handleUndo,
  handleSave,
  disabledButton,
}) => {
  const classes = useStyles();
  

  return (
    <Box display='flex' alignItems='center'>
          {show && showDelete && (
              <Tooltip title='Eliminar'>
                  <Box mr={1}>
                      <Button
                          variant='contained'
                          onClick={handleDelete}
                          color='error'
                          className={classes.buttonIcon}>
                          <DeleteRoundedIcon color='white' />
                      </Button>
                  </Box>
              </Tooltip>
      )}
      {show && (
        <Hidden smDown>
          <Box mr={1}>
            <Tooltip title='Deshacer'>
              <Button
                variant='contained'
                onClick={handleUndo}
                color='primary'
                className={`${classes.buttonIcon} ${classes.undo}`}>
                <ReplayRoundedIcon color='white' />
              </Button>
            </Tooltip>
          </Box>
        </Hidden>
      )}

      <Hidden smDown>
        <Button
          variant='contained'
          onClick={handleSave}
          size='medium'
          disabled={disabledButton}
          endIcon={<SaveRoundedIcon />}>
          Guardar
        </Button>
      </Hidden>
      <Hidden smUp>
        <Button
          variant='contained'
          onClick={handleSave}
          disabled={disabledButton}
          className={classes.buttonIcon}>
          <SaveRoundedIcon color='white' />
        </Button>
      </Hidden>
    </Box>
  );
};

export default FormActions;
