import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => {
    return {
        container: {
            [theme.breakpoints.down('sm')]: {
                overflowY: 'auto',
                maxWidth: '100%',
                margin: '15px',
            }
        },
    globalSearchContainer: {
      display: 'flex',
      padding: '16px 0',
      justifyContent: 'end',
        [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    search: props => ({
      borderRadius: props.global ? 5 : 3,
      border: props.global ? '1px solid #d7d7d7' : '1px solid #ababab',
      outline: 'none',
      paddingLeft: 35,
      fontSize: 16,
      height: props.global ? '40px' : '35px',
      //width: props.global ? 250 : 150,
    }),
    searchContainer: {
      position: 'relative',
    },
    searchIcon: props => ({
      position: 'absolute',
      left: 6,
      top: props.global ? 9 : 6,
      color: '#d7d7d7',
    }),

    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    footerResult: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        marginBottom: '15px',
      },
    },
    footerPagination: {
      display: 'flex',
      alignItems: 'center',
    },
  };
});
