import apiRequest from '@helpers/apiRequest';

const postSignIn = data => {
  return apiRequest('POST', 'Login/SignIn', data);
};

const lostPassword = data => {
  return apiRequest('POST', 'Login/LostMyPassword', data);
};

const setNewPassword = data => {
  return apiRequest('POST', 'Login/SetNewPassword', data);
};

const checkIsAuthenticated = () => {
  return apiRequest('GET', 'Login/Ping');
};
const serviceLogin = {
  postSignIn,
  lostPassword,
  setNewPassword,
  checkIsAuthenticated,
};

export default serviceLogin;
