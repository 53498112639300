import apiRequest from '@helpers/apiRequest';

const getProviders = () => {
    return apiRequest('GET', `Providers/List`);
};

const getProviderDetails = idProvider => {
  return apiRequest('GET', `Providers/Details/${idProvider}`);
};

const updateProvider = data => {
  const idProvider = data.idProvider;
  return apiRequest('PUT', `Providers/Update/${idProvider}`, data);
};

const newProvider = data => {
  return apiRequest('POST', 'Providers/New', data);
};

const deleteProvider = data => {
  const idProvider = data.idProvider;
  return apiRequest('DELETE', `Providers/Delete/${idProvider}`);
};
const serviceProviders = {
  getProviders,
  getProviderDetails,
  updateProvider,
  newProvider,
  deleteProvider,
};

export default serviceProviders;
