import {
    MapsHomeWork,
    ContactSupport,
    LocalOffer,
    ContentPasteSearch,
    Assignment,
    HomeRepairService,
    Task,
    Unsubscribe,
    Group,
    GroupAdd,
    SwipeLeft,
    ShoppingCart,
    Policy,
    PendingActions,
    CheckBox,
    MailOutline,
    CreditCard,
    Download,
    Build
} from '@mui/icons-material';

const userSession = JSON.parse(localStorage.getItem('userSession'));
const menuItems = [];

menuItems.push(
    {
        menuTitle: "Expedientes por estado",
        items: [
            {
                title: "Oportunidad",
                url: '/requests/1',
                icon: <ContactSupport />,
            },
            {
                title: "Propuesta inicial",
                url: '/requests/2',
                icon: <LocalOffer />,
            },
            {
                title: "Visita técnico",
                url: '/requests/3',
                icon: <ContentPasteSearch />,
            }
            ,
            {
                title: "Firma contrato",
                url: '/requests/4',
                icon: <Assignment />,
            },
            {
                title: "Pedido material",
                url: '/requests/5',
                icon: <ShoppingCart />,
            },
            {
                title: "Instalación",
                url: '/requests/6',
                icon: <HomeRepairService />,
            },
            {
                title: "Legalización",
                url: '/requests/7',
                icon: <Policy />,
            }
            ,
            {
                title: "Completados",
                url: '/requests/8',
                icon: <Task />,
            }
            ,
            {
                title: "Cancelados",
                url: '/requests/9',
                icon: <SwipeLeft />,
            }
        ],
    });


menuItems.push({
    menuTitle: "Control subvenciones",
    items: [
        {
            title: "Pte. tramitar",
            url: '/requestsgrants/1',
            icon: <ContactSupport />,
        },
        {
            title: "Esperando respuesta",
            url: '/requestsgrants/2',
            icon: <PendingActions />,
        },
        {
            title: "Completados",
            url: '/requestsgrants/3',
            icon: <CheckBox />,
        }
    ],
});

if (userSession?.idRole !== 2) {
    menuItems.push({
        menuTitle: "Proveedores",
        items: [
            {
                title: "Proveedores",
                url: '/providers',
                icon: <HomeRepairService />,
            }
        ],
    });

    menuItems.push({
        menuTitle: "Configuración",
        items: [
            {
                title: "Usuarios",
                url: '/users',
                icon: <Group />,
            },
            {
                title: "Orígenes de leads",
                url: '/origins',
                icon: <Download />,
            },
            {
                title: "Plantillas de e-mail",
                url: '/mailings',
                icon: <MailOutline />,
            },
            {
                title: "Formas de pago",
                url: '/payments',
                icon: <CreditCard />,
            }, {
                title: "Configuración",
                url: '/configs',
                icon: <Build />,
            }
        ],
    })
}



export default menuItems;
