import React, { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Dialog from '@components/Dialog';
import FormActions from '@components/FormActions';
import HeaderTitle from '@components/HeaderTitle';
import { useForm } from '@hooks/useForm';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import FileBase64 from 'react-file-base64';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { Backdrop,CircularProgress, Box, Button, Grid, TextField, Typography, Step, Stepper, StepLabel, Paper, Tab, IconButton, Input, InputAdornment, MenuItem, Select, FormControl } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab'
import serviceRequests from '@services/serviceRequests';
import serviceRequestsHistory from '@services/serviceRequestsHistory';
import serviceProviders from '@services/serviceProviders';
import serviceOrigins from '@services/serviceOrigins';
import servicePayments from '@services/servicePayments';
import serviceMailing from '@services/serviceMailing';
import serviceUsers from '@services/serviceUsers';
import { useCommonStyles } from '@styles/common.style';
import { useLocation, useNavigate } from 'react-router-dom';
import Moment from 'react-moment';

const RequestDetail = () => {

    const Input = styled('input')({ display: 'none', });
    const navigate = useNavigate();
    const classes = useCommonStyles();
    const { state: { data: USER_DATA, type: TYPE }, } = useLocation();
    const [openDialog, setOpenDialog] = useState(false);
    const [backdrop, setBackdrop] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [payments, setPayments] = useState([]);
    const [origins, setOrigins] = useState([]);
    const [historyDesc, setHistoryDesc] = useState("");
    const { formData, setFormData, handleChange } = useForm(USER_DATA);
    const userSession = JSON.parse(localStorage.getItem('userSession'));
        

    const getDateTimeStatusChange = (idStatus) => {
        if (idStatus == 1) {
            return <Moment format="DD/MM/YYYY HH:mm">
                {formData.requestCreated}
            </Moment>
        }
        var history = formData.requestHistory;
        var data = history.filter(x => x.idRequestStatus == idStatus);
        if (data.length > 0) {
            return (
                <Moment format="DD/MM/YYYY HH:mm">
                    {data[0].requestHDateTime}
                </Moment>
            )
        }
        else { return ' - '; }
    }

    const changeQuotationLine = (index, key, value) => {
        var quotation = formData.requestQuotation;
        var updateLine = quotation[index];
        updateLine = { ...updateLine, [key]: value };
        formData.requestQuotation[index] = updateLine;
        setFormData(prev => ({ ...prev, requestQuotation: quotation }));
    }

    // Actualizar desde GSheet
    const syncGSheet = (e) => {
            setBackdrop(true);
            serviceRequests.updateRequest(formData).then(response => {
                if (!response.error) {
                    serviceRequests.SyncGSheet(formData.idRequest).then(response => {
                        setFormData(prevState => {
                            return {
                                ...prevState,
                                requestGSheetUID: response.data.requestGSheetUID,
                                requestQuotation: response.data.requestQuotation,
                                requestAmount: response.data.requestAmount,
                            };
                        });
                        serviceRequests.getRequestFiles(formData.idRequest).then(response => {
                            if (!response.error) {
                                setRequestFiles(response.data);
                            }
                        });
                        setBackdrop(false);
                    })
                }
            })        
    }

    // Añadir evento al historial
    const addHistory = () => {
        var data = { idRequest: formData.idRequest, RequestHContent: historyDesc };
        setHistoryDesc("");
        serviceRequestsHistory.newRequestHistory(data).then(response => {
            if (!response.error) {
                serviceRequests.getRequestDetails(formData.idRequest).then(response => {
                    setFormData(prev => ({ ...prev, requestHistory: response.data.requestHistory }));
                })
            }
        })
    }

    // Enviar oferta al cliente
    const sendOffer = () => {
        setBackdrop(true);
        serviceMailing.sendOffer(formData.idRequest).then(response => {
            if (!response.error) {
                serviceRequests.getRequestDetails(formData.idRequest).then(response => {
                    setFormData(prev => ({ ...prev, requestHistory: response.data.requestHistory }));
                })
                setBackdrop(false);
            } else {
                setBackdrop(false);
            }
        })
    }

    // Ficheros del expediente
    const uploadRequestFile = (e) => {
        const file = e.base64.replace('data:' + e.type + ';base64,', '');
        serviceRequests.postRequestFile(formData.idRequest, { FileName: e.name, FileContent: file }).then(response => {
            if (!response.error) {
                setRequestFiles(response.data);
            }
        })
    }

    // Ficheros del expediente
    const downloadRequestFile = (fileName) => {
        serviceRequests.downloadRequestFile(formData.idRequest, { FileName: fileName, FileContent: '' }).then(response => {
            if (!response.error) {
                const linkSource = 'data:application/pdf;base64,' + response.data.fileContent;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = response.data.fileName;
                downloadLink.click();
            }
        })
    }

    const deleteRequestFile = (fileName) => {
        serviceRequests.deleteRequestFile(formData.idRequest, { FileName: fileName, FileContent: '' }).then(response => {
            if (!response.error) {
                setRequestFiles(response.data);
            }
        })
    }

    const HEADER_TITLE = TYPE === 'NEW' ? 'Nuevo expediente' : 'Editar expediente: '+formData.idRequest;
    const HEADER_SUBTITLE = TYPE === 'NEW' ? 'Crea un nuevo expediente' : 'Puedes editar, eliminar y actualizar los datos del expediente';

    const disabledSaveButton = USER_DATA === formData;
    const [roles, setRoles] = useState(null);
    const [requestStatusName, setRequestStatusName] = useState([]);
    const [requestFiles, setRequestFiles] = useState([]);
    const [providers, setProviders] = useState([]);
    const [userNames, setUserNames] = useState([]);
    const [tabValue, setTabValue] = React.useState('1');
    const handleTab = (event, newValue) => { setTabValue(newValue); };

    const handleSubmit = type => {
    switch (type) {
      case 'NEW':
        serviceRequests.newRequest(formData).then(handleResponse);
        break;
      case 'UPDATE':
        serviceRequests.updateRequest(formData).then(handleResponse);
        break;
        case 'DELETE':
            formData.idRequestStatus = 9;
            serviceRequests.updateRequest(formData).then(handleResponse);
        break;
      default:
        break;
    }
  };

    const handleResponse = response => {
    if (!response.error) {
      navigate('/requests');
    }
    };

    const handleChangeImage = e => {
    const image = e.base64.replace('data:' + e.type + ';base64,', '');
    setFormData(prevState => {
      return { ...prevState, requestImage: image };
    });
    };

    useEffect(() => {
        serviceUsers.getUserNames().then(response => {
            if (!response.error) {
                setUserNames(response.data);
            }
        });
        serviceProviders.getProviders().then(response => {
            if (!response.error) {
                setProviders(response.data);
            }
        });
        serviceOrigins.getOrigins().then(response => {
            if (!response.error) {
                setOrigins(response.data);
            }
        });
        servicePayments.getPayments().then(response => {
            if (!response.error) {
                setPayments(response.data);
            }
        });
        serviceRequests.getRequestsStatus().then(response => {
            if (!response.error) {
                setRequestStatusName(response.data);
            }
        });
        serviceRequests.getRequestFiles(formData.idRequest).then(response => {
            if (!response.error) {
                setRequestFiles(response.data);
            }
        });
    }, []);

    const userNameById = (idxUser) => {
        if (idxUser) {
            return userNames.filter(user => user.idUser == idxUser)[0].userName.toUpperCase();
        } else {
            return "AHORROSOLAR.NET";
        }
    }


  return (
    <div className={classes.container}>
      <Dialog
        handleOpen={openDialog}
        hasCloseBtn={false}
        handleClose={() => setOpenDialog(false)}
        handleOk={() => handleSubmit('DELETE')}
        title='¿Seguro que quieres cancelar el expediente?'
        subtitle='Podrás seguir viéndolo en la sección CANCELADOS'
        height={200}
      />

          <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
              {userSession?.idRole != 2 &&
                  <FormActions
                      show={TYPE === 'UPDATE'}
                      handleDelete={() => setOpenDialog(true)}
                      handleUndo={() => setFormData(USER_DATA)}
                      handleSave={() => handleSubmit(TYPE)}
                      disabledButton={disabledSaveButton}
                  />
              }
      </HeaderTitle>

          <Grid container spacing={4}>
              {formData.idRequest &&
                  <Grid
                      item
                      xs={12}
                      mt={4}
                  >
                      <Stepper activeStep={formData.idRequestStatus == 8 ? 8 : formData.idRequestStatus > 8 ? -1 : formData.idRequestStatus - 1} alternativeLabel>
                          {requestStatusName.map((status) => {
                              if (status.idRequestStatus < 9) {
                                  return (
                                      <Step key={status.idRequestStatus}>
                                          <StepLabel style={{ cursor: userSession?.idRole != 2 ? 'pointer' : '' }} onClick={() => { if (userSession?.idRole != 2) { setFormData(prevState => { return { ...prevState, idRequestStatus: status.idRequestStatus }; }) } }}>
                                              {status.requestStatusName}<br />
                                              <small>{getDateTimeStatusChange(status.idRequestStatus)}</small>
                                          </StepLabel>
                                      </Step>
                                  )
                              }
                          })
                          }
                      </Stepper>
                  </Grid>
              }
                <Grid
                  item
                  lg={ formData.idRequest ? 4: 12}
                  xs={12}
                  sm={formData.idRequest ? 6 : 12}
                          mt={2}
                >
                  <Typography color='primary' variant='subtitle1' mb={1} style={{ borderBottom: '1px solid lightgray' }}>
                    Datos de contacto del cliente
                  </Typography>
                  <Box>
                      <Grid container spacing={2}>
                          <Grid item xs={6}>
                              <TextField
                                  label='Nombre del cliente'
                                  name='clientName'
                                  value={formData.clientName || ''}
                                  size='small'
                                  onChange={handleChange}
                                  fullWidth
                                  margin='normal'
                                      />
                        </Grid>
                        <Grid item xs={6}>
                              <TextField
                                  label='CIF'
                                  name='clientCif'
                                  value={formData.clientCif || ''}
                                  size='small'
                                  onChange={handleChange}
                                  fullWidth
                                  margin='normal'
                                      />
                          </Grid>
                          </Grid>
                      <Grid container spacing={2}>
                          <Grid item xs={6}>
                              <TextField
                                  label='Teléfono'
                                  name='clientPhone'
                                  value={formData.clientPhone || ''}
                                  size='small'
                                  onChange={handleChange}
                                  fullWidth
                                  margin='normal'
                              />
                          </Grid>
                          <Grid item xs={6}>
                              <TextField
                                  label='E-Mail'
                                  name='clientEmail'
                                  value={formData.clientEmail || ''}
                                  size='small'
                                  onChange={handleChange}
                                  fullWidth
                                  margin='normal'
                              />
                          </Grid>
                      </Grid>
                    
                    
                    

                          </Box>
                  <Typography color='primary' variant='subtitle1' mt={1} mb={1} style={{ borderBottom:'1px solid lightgray'}}>
                              Dirección de la vivienda
                          </Typography>
                          <Box>

                      <Grid container spacing={2}>
                          <Grid item xs={3}>
                              <TextField
                                  label='Postal'
                                  name='clientZip'
                                  value={formData.clientZip || ''}
                                  size='small'
                                  onChange={handleChange}
                                  fullWidth
                                  margin='normal'
                              />
                          </Grid>
                          <Grid item xs={9}>
                              <TextField
                                  label='Dirección'
                                  name='clientAddress'
                                  value={formData.clientAddress || ''}
                                  size='small'
                                  onChange={handleChange}
                                  fullWidth
                                  margin='normal'
                              />
                          </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                          <Grid item xs={6}>
                              <TextField
                                  label='Ciudad'
                                  name='clientCity'
                                  value={formData.clientCity || ''}
                                  size='small'
                                  onChange={handleChange}
                                  fullWidth
                                  margin='normal'
                              />
                          </Grid>
                          <Grid item xs={6}>
                              <TextField
                                  label='Provincia'
                                  name='clientProvince'
                                  value={formData.clientProvince || ''}
                                  size='small'
                                  onChange={handleChange}
                                  fullWidth
                                  margin='normal'
                              />
                          </Grid>
                      </Grid>
                              
                              
                  </Box>
                  <Box>
                      <Grid container spacing={2}>
                          <Grid item xs={ formData.idRequest ? 6 : 4 }>
                              <Typography color='primary' variant='subtitle1' mt={1} mb={1} style={{ borderBottom: '1px solid lightgray' }}>
                                  Gestión de subvención
                              </Typography>
                              <FormControl sx={{ marginTop: '5px' }} fullWidth size="small">
                                  <Select
                                      value={formData.idRequestGrantStatus}
                                      name="idRequestGrantStatus"
                                      onChange={handleChange}
                                  >
                                      <MenuItem value={0}>No requiere gestión</MenuItem>
                                      <MenuItem value={1}>Pendiente trámite</MenuItem>
                                      <MenuItem value={2}>Esperando respuesta</MenuItem>
                                      <MenuItem value={3}>Completados</MenuItem>
                                  </Select>
                              </FormControl>
                          </Grid>
                          <Grid item xs={formData.idRequest ? 6 : 4}>
                              <Typography color='primary' variant='subtitle1' mt={1} mb={1} style={{ borderBottom: '1px solid lightgray' }}>
                                  Origen del lead
                              </Typography>
                              <FormControl sx={{ marginTop: '5px' }} fullWidth size="small">
                                  <Select
                                      value={formData.idOrigin || 1}
                                      name="idOrigin"
                                      onChange={handleChange}
                                  >
                                      {
                                          origins.map((origin, i) => (
                                              <MenuItem value={origin.idOrigin}>{origin.originName}</MenuItem>
                                          ))}
                                  </Select>
                              </FormControl>
                          </Grid>
                          <Grid item xs={formData.idRequest ? 6 : 4}>
                              <Typography color='primary' variant='subtitle1' mt={1} mb={1} style={{ borderBottom: '1px solid lightgray' }}>
                                  Forma de pago
                              </Typography>
                              <FormControl sx={{ marginTop: '5px' }} fullWidth size="small">
                                  <Select
                                      value={formData.idPayment || 1}
                                      name="idPayment"
                                      onChange={handleChange}
                                  >
                                      {
                                          payments.map((payment, i) => (
                                              <MenuItem value={payment.idPayment}>{payment.paymentName}</MenuItem>
                                          ))}
                                  </Select>
                              </FormControl>
                          </Grid>{formData.idRequest &&
                              <Grid item xs={6}>
                                  <Typography color='primary' variant='subtitle1' mt={1} mb={1} style={{ borderBottom: '1px solid lightgray' }}>
                                      Responsable del expediente
                                  </Typography>
                                  <FormControl sx={{ marginTop: '5px' }} fullWidth size="small">
                                      <Select
                                          value={formData.idUser || 0}
                                          name="idUser"
                                          onChange={handleChange}
                                      >
                                          <MenuItem value={0}>No definido</MenuItem>
                                          {
                                              userNames.map((usr, i) => (
                                                  <MenuItem value={usr.idUser}>{usr.userName}</MenuItem>
                                              ))}
                                      </Select>
                                  </FormControl>                          </Grid>}
                      </Grid>
                      
                  </Box>

                  <Box>

                  </Box>

                      </Grid>
                <Grid
                          item
                          lg={8}
                          xs={12}
                          sm={6}
                          mt={2}
                      >
                  <Box sx={{ width: '100%', typography: 'body1' }} >
                      {formData.idRequest &&
                          <TabContext value={tabValue}>
                              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                  <TabList onChange={handleTab} aria-label="lab API tabs example">
                                  <Tab label="COTIZACIÓN" value="1" />
                                  {userSession?.idRole != 2 &&
                                      <Tab label="ARCHIVOS" value="2" />
                                  }
                                  {userSession?.idRole != 2 &&
                                      <Tab label="HISTORIAL" value="3" />
                                  }
                                  </TabList>
                              </Box>
                              <TabPanel style={{ padding: '20px 0px' }} value="1">
                                  <TableContainer component={Paper} >
                                      <Table size="small" >
                                          <TableHead>
                                              <TableRow>
                                                  <TableCell width="5%">Cantidad</TableCell>
                                              <TableCell width="60%">Detalle</TableCell>
                                              {userSession?.idRole != 2 &&
                                                  <TableCell width="35%">Proveedor</TableCell>
                                              }
                                              </TableRow>
                                          </TableHead>
                                          <TableBody>
                                              {
                                                  formData.requestQuotation.map((line, i) => (
                                                      <TableRow>
                                                          <TableCell style={{ padding: '18px' }}>
                                                              {formData.requestQuotation[i].requestQQty || ''}
                                                          </TableCell>
                                                          <TableCell>
                                                              {formData.requestQuotation[i].requestQDesc || ''}
                                                          </TableCell>
                                                          {userSession?.idRole != 2 &&
                                                              <TableCell>
                                                                  <FormControl fullWidth size="small">
                                                                      <Select
                                                                          value={formData.requestQuotation[i].idProvider || 0}
                                                                          name="idProvider"
                                                                          onChange={e => changeQuotationLine(i, e.target.name, e.target.value)}
                                                                      >
                                                                          <MenuItem value={0}>Sin asignar</MenuItem>
                                                                          {
                                                                              providers.map((provider, i) => (
                                                                                  <MenuItem value={provider.idProvider}>{provider.providerName + ' ' + provider.providerSurname}</MenuItem>
                                                                              ))}
                                                                      </Select>
                                                                  </FormControl>
                                                              </TableCell>
                                                          }
                                                      </TableRow>
                                                  ))}
                                          </TableBody>
                                      </Table>
                                      <Table>
                                          <TableBody>
                                              <TableRow>
                                              <TableCell width="67%" rowSpan={3} style={{ verticalAlign: 'top' }} >
                                                  {userSession?.idRole != 2 &&
                                                      <>
                                                      <strong>ACCIONES:</strong><br /><br />
                                                      <Grid container spacing={2}>
                                                          <Grid item xs={6}><Button fullWidth variant="outlined" onClick={syncGSheet}>Sincronizar con Google</Button></Grid>
                                                          <Grid item xs={6}></Grid>
                                                          <Grid item xs={6}><Button fullWidth variant="outlined" onClick={() => sendOffer()}>Enviar oferta por e-mail</Button></Grid>
                                                          <Grid item xs={6}></Grid>
                                                      </Grid>
                                                      </>
                                                  }
                                                  </TableCell>
                                                  <TableCell align="right"><strong>Subtotal</strong></TableCell>
                                                  <TableCell align="right">
                                                      {formData.requestAmount.toFixed(2).replace(",", "").replace(".", ",")}€
                                                  </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                  <TableCell align="right"><strong>IVA {formData.requestTaxes.toFixed(2).replace(",", "").replace(".", ",")}%</strong></TableCell>
                                                  <TableCell align="right">
                                                      {(formData.requestAmount / 100 * formData.requestTaxes).toFixed(2).replace(",", "").replace(".", ",")}€
                                                  </TableCell>
                                              </TableRow>
                                              <TableRow>
                                                  <TableCell align="right"><strong>TOTAL</strong></TableCell>
                                                  <TableCell align="right">
                                                      {(formData.requestAmount / 100 * (100 + formData.requestTaxes)).toFixed(2).replace(",", "").replace(".", ",")}€
                                                  </TableCell>
                                              </TableRow>
                                          </TableBody>
                                      </Table>
                                  </TableContainer>

                          </TabPanel>
                          {userSession?.idRole != 2 &&
                              <TabPanel value="2" style={{ padding: '0px', marginTop: '20px' }}>
                                  <Paper style={{ padding: '20px' }}>
                                      <Grid container spacing={2}>
                                          {formData.requestGSheetUID &&
                                              <Grid item xs={2} padding={2} align="center">
                                                  {/*Enlace a Google Sheets*/}
                                                  <Box style={{ width: '60px', cursor: 'pointer' }} onClick={() => { window.open('https://docs.google.com/spreadsheets/d/' + formData.requestGSheetUID, '_blank').focus(); }}>
                                                      <FileIcon extension="Gsheet" {...defaultStyles["xlsx"]} />
                                                  </Box>
                                                  Cálculo de oferta
                                              </Grid>
                                          }
                                          {
                                              requestFiles.map((file, i) => (
                                                  <Grid item xs={2} padding={2} align="center"
                                                      onMouseOver={() => { document.querySelector('#trash-' + i).style.display = 'block' }}
                                                      onMouseOut={() => { document.querySelector('#trash-' + i).style.display = 'none' }}
                                                  >
                                                      <Box style={{ width: '60px', cursor: 'pointer' }} onClick={() => { downloadRequestFile(file.fileName) }}>
                                                          <FileIcon extension={file.fileExt} {...defaultStyles[file.fileExt]} />
                                                      </Box>
                                                      {file.fileName}
                                                      <IconButton id={'trash-' + i} size="small" style={{ display: 'none', position: 'absolute', marginTop: '-120px' }}>
                                                          <DeleteIcon fontSize="small" onClick={() => deleteRequestFile(file.fileName)} />
                                                      </IconButton>
                                                  </Grid>
                                              ))
                                          }

                                      </Grid>
                                      <Grid container spacing={2} padding={4}>
                                          <Grid item xs={12} align="center">
                                              <div id='upload-button' style={{ display: 'none' }}>
                                                  <FileBase64 multiple={false} onDone={uploadRequestFile} />
                                              </div>
                                              <Button variant="outlined" style={{ marginTop: requestFiles.length > 0 ? "30px" : "0px" }}
                                                  onClick={() => {
                                                      document.querySelector('#upload-button>input').click();
                                                  }}>
                                                  Subir fichero
                                              </Button>
                                          </Grid>
                                      </Grid>
                                  </Paper>
                              </TabPanel>
                          }
                          {userSession?.idRole != 2 &&
                              <TabPanel value="3" style={{ padding: '0px', marginTop: '20px' }}>
                                  <Grid container spacing={2}>
                                      <Grid item xs={9}>
                                          <TextField
                                              label='Contenido del historial'
                                              name='historyDesc'
                                              size='small'
                                              value={historyDesc}
                                              onChange={(e) => { setHistoryDesc(e.target.value) }}
                                              fullWidth
                                          />
                                      </Grid>
                                      <Grid item xs={3}>
                                          <Button fullWidth variant="outlined" onClick={addHistory}>AÑADIR</Button>
                                      </Grid>
                                  </Grid>
                              {
                                  userNames.length > 0 &&
                                      formData.requestHistory.map((history, i) => (
                                          <Paper style={{ padding: '20px', marginTop: '20px' }}>
                                              <Grid container spacing={2}>
                                                  <Grid item xs={8}>
                                                      <small>
                                                          <strong>{history.requestHTitle.toUpperCase()}</strong><br/>
                                                          <span style={{ color: 'darkred' }}>{ userNameById(history.idUser)}</span>
                                                      </small>
                                                  </Grid>
                                                  <Grid item xs={4} align="right">
                                                      <small><strong><Moment format="DD/MM/yyyy HH:mm">{history.requestHDateTime}</Moment></strong></small>
                                                  </Grid>
                                                  <Grid item xs={12}>
                                                      {history.requestHContent}
                                                  </Grid>
                                              </Grid>
                                          </Paper>
                                      ))
                                  }
                              </TabPanel>
                          }
                          </TabContext>
                      }
                          </Box>
                      </Grid>
          </Grid>
          <Backdrop
              sx={{ color: '#fff', zIndex: 9999 }}
              open={backdrop}
          >
              <CircularProgress color="inherit" />
          </Backdrop>
          <Dialog children="Aún no se ha generado el fichero de cálculo en Google Sheets" handleOpen={dialog} title="Error de importación" handleClose={() => setDialog(false)} handleOk={() => setDialog(false)} />
    </div>
  );
};

export default RequestDetail;
