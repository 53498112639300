import React, { Fragment, useContext, useState, useEffect } from 'react';
import Fallback from '@assets/images/avatar.png';
import MenuIcon from '@mui/icons-material/Menu';
import { AuthContext } from '@context/auth/AuthContext';
import { Logout } from '@mui/icons-material';
import {
  AppBar,
  Avatar,
  Box,
  Grid,
  Hidden,
  IconButton,
  Stack,
  Switch,
  Toolbar,
  Typography,
} from '@mui/material';
import { useStyles } from '@styles/header.style';

import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';

const Header = ({ handleMenuPosition, handleShowMenu }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { user, logout } = useContext(AuthContext);

  const [openLanguage, setOpenLanguage] = useState(false);
  const [openModule, setOpenModule] = useState(false);
  const [language, setLanguage] = useState(null);
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setNow(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Fragment>
      <Hidden mdDown>
        {/* Menú xl */}
        <AppBar position='static'>
          <Toolbar className={classes.header}>


            
              <Grid>
                          <Typography color="secondary" fontSize={27}>
                              AhorroSolar.net
                          </Typography><hr />
                <Stack direction='row' alignItems='center'>
                  <Typography variant='button' mr={1}>
                    <Moment format='DD[/]MM[/]yyyy'>{now}</Moment>
                  </Typography>
                  <Typography variant='button' fontSize={14}>
                    <Moment format='HH:mm:ss'>{now}</Moment>
                  </Typography>
                  <Switch
                    defaultChecked
                    color='secondary'
                    onChange={handleMenuPosition}
                                  />
                </Stack>
              </Grid>
            

            <Grid display='flex' alignItems='center'>
                          <Grid display='flex' flexDirection='column' mr={3}>
                              <Stack
                                  direction='row'
                                  justifyContent='end'
                                  alignItems='center'
                                  spacing={1}>
                                  <Typography color="secondary">
                                      {user?.userEmail}
                                  </Typography>
                              </Stack>
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='end'
                  spacing={1}>
                  <Typography>
                    {user?.userName} {user?.userSurname}
                  </Typography>
                </Stack>
                              <Stack
                                  direction='row'
                                  alignItems='center'
                                  justifyContent='end'
                                  spacing={1}>
                                  <Typography onClick={logout} className={classes.btn}>
                                      Cerrar sesión
                                  </Typography>
                              </Stack>

              </Grid>
              <Avatar
                sx={{ width: 60, height: 60 }}
                src={`data:image/png;base64,${user?.userImage}` || Fallback}
              />
            </Grid>
          </Toolbar>
        </AppBar>
      </Hidden>

      {/* Menú xs */}
      <Hidden mdUp>
        <AppBar position='static'>
                  <Toolbar className={classes.header}>
                      <Box>
                          <IconButton
                              size='large'
                              onClick={() => handleShowMenu(prevState => !prevState)}
                              color='inherit'>
                              <MenuIcon />
                          </IconButton>
                      </Box>
                      <Typography className={classes.headerTitle}>
                          AhorroSolar.net
                      </Typography>

            <IconButton size='large' onClick={logout} color='inherit'>
              <Logout />
            </IconButton>
          </Toolbar>
        </AppBar>
      </Hidden>
    </Fragment>
  );
};

export default Header;
