import { Button, FormControlLabel, FormGroup, Grid, TextField, Checkbox, Alert, Box } from '@mui/material';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import React, { useState, useEffect } from 'react';
import { useForm } from '@hooks/useForm';
import { useIsMobile } from '@hooks/useIsMobile';
import serviceRequests from '../../services/serviceRequests';
import { FileUploader } from "react-drag-drop-files";
import { PanoramaSharp, PhotoCamera } from '@mui/icons-material';
import { useParams } from "react-router-dom";


const Public = () => {
    const params = useParams();
    console.log(params);
    
    const [idUser,setIdUser] = useState(0);
    const isMobile = useIsMobile();
    const [address, setAddress] = useState();
    const [isDark, setIsDark] = useState(true);
    const [idRequest, setIdRequest] = useState(null);
    const [legal, setLegal] = useState(false);
    const [stepTwo, setStepTwo] = useState(1);
    const [alertError, setAlertError] = useState('');
    const [addressObj, setAddressObj] = useState();
    const [invoice, setInvoice] = useState(null);


    const { formData, setFormData, handleChange } = useForm({
        clientName: '',
        clientPhone: '',
        clientEmail: '',
        clientCity: '',
        clientZip: '',
        clientProvince: '',
        clientAddress: '',
        clientCups: '',
        clientCompany: '',
        clientInvAmount: '',
        clientPower: ''
    });

    const getAddressObject = (address_components) => {
        console.log(address_components);
        const ShouldBeComponent = {
            street_number: ["street_number"],
            postal_code: ["postal_code"],
            street: ["street_address", "route"],
            province: [
                "administrative_area_level_2",
                "administrative_area_level_3",
                "administrative_area_level_4",
                "administrative_area_level_5"
            ],
            city: [
                "locality",
                "sublocality",
                "sublocality_level_1",
                "sublocality_level_2",
                "sublocality_level_3",
                "sublocality_level_4"
            ],
            country: ["country"]
        };

        let address = {
            street_number: "",
            postal_code: "",
            street: "",
            province: "",
            city: "",
            country: ""
        };

        address_components.forEach((component) => {
            for (var shouldBe in ShouldBeComponent) {
                if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                    if (shouldBe === "country") {
                        address[shouldBe] = component.short_name;
                    } else {
                        address[shouldBe] = component.long_name;
                    }
                }
            }
        });

        // Fix the shape to match our schema
        address.address = address.street + ", " + address.street_number;
        delete address.street_number;
        delete address.street;
        return address;
    };

    useEffect(() => {
        if (params.theme) {
            if (params.theme === "dark") { setIsDark(true); }
            else { setIsDark(false); }
        }
        if (params.idUser) {
            setIsDark(false);
            setIdUser(params.idUser);
            setLegal(true);
        }
    }, []);

    useEffect(() => {
        const func = async () => {
            const geocodeObj =
                address &&
                address.value &&
                (await geocodeByPlaceId(address.value.place_id));
            const addressObject = geocodeObj && getAddressObject(geocodeObj[0].address_components);
            if (addressObject) {
                setFormData(prev => ({
                    ...prev,
                    clientCity: addressObject.city,
                    clientZip: addressObject.postal_code,
                    clientProvince: addressObject.province,
                    clientAddress: addressObject.address,
                }));
            }
            setAddressObj(addressObject);
        };
        func();
    }, [address]);

    const sendData = () => {
        var errorlist = "";
        var checkMail = true;
        if (formData.clientName == '') { errorlist = errorlist + "Nombre, " };
        if (formData.clientPhone == '') { errorlist = errorlist + "Teléfono, " };
        if (formData.clientEmail == '') {
            errorlist = errorlist + "E-mail, ";
        } else {
            var regex = /\S+@\S+\.\S+/;
            checkMail = regex.test(formData.clientEmail);
        }
        if (formData.clientCity == '') { errorlist = errorlist + "Dirección, " };
        if (!legal) { errorlist = errorlist + "Aceptar política de tratamiento de datos, " };
        if (!checkMail) { errorlist = errorlist + "El formato de e-mail no es correcto, " };

        

        if (errorlist.length > 0) {
            setAlertError(errorlist.substring(0, errorlist.length - 2) + ".");
        } else {
            serviceRequests.newPublicRequest(formData).then(response => {
                if (!response.error) {
                    setIdRequest(response.data);
                    setStepTwo(2);
                }
            })
        }
    }

    const sendExtradata = () => {
        var data = {
            idRequest: idRequest,
            clientCups: formData.clientCups ?? '',
            clientInvAmount: formData.clientInvAmount ?? '',
            clientPower: formData.clientPower ?? '',
            clientCompany: formData.clientCompany ?? ''
        };
        serviceRequests.postPublicUpdate(data).then(response => {
            if (!response.error) {
                setIdRequest(response.data);
                setStepTwo(4);
            }
        })
    }
     
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const uploadInvoice = async (invoice) => {
        console.log(invoice);
        var base64file = await toBase64(invoice);
        var file = base64file.replace('data:' + invoice.type + ';base64,', '');
        serviceRequests.postRequestPublicFile(idRequest, { FileName: "Factura cliente." + invoice.name.split('.').pop(), FileContent: file }).then(response => {
            if (!response.error) {
                setStepTwo(4);
            }
        })
 
    }

    return (
        <div style={{
            height: '450px'
        }}>
            {stepTwo == 1 &&
                <Grid container spacing={isMobile?2:3} padding={2}>
                    <Grid item xs={12}>
                    {
                        idUser>0 ?
                        <>
                        <h1 style={{ fontSize: isMobile ? '26px' : '30px', color: isDark ?'#ffc022':'black' }}>Inserción de nueva oportunidad por labor comercial</h1>
                        <h3 style={{ fontSize: isMobile ? '15px' : '23px', color: isDark ? 'white' : 'black', marginTop: '3px' }}>Rellena el siguiente formulario y se generará la solicitud</h3>
                        </>
                        :
                        <>
                        <h1 style={{ fontSize: isMobile ? '26px' : '30px', color: isDark ?'#ffc022':'black' }}>¿Quieres conocer online el coste de instalar placas solares en tu vivienda?</h1>
                        <h3 style={{ fontSize: isMobile ? '15px' : '23px', color: isDark ? 'white' : 'black', marginTop: '3px' }}>Rellena el siguiente formulario y recibirás GRATIS tu presupuesto</h3>
                        </>
                    }
                    
                    </Grid>
                <Grid item xs={12}><TextField required name="clientName" onChange={handleChange} InputLabelProps={{ shrink: false }} variant="outlined" label={formData.clientName == '' ? 'Nombre' : ''} size={isMobile ? "small" : "normal"} fullWidth InputProps={{ style: { backgroundColor: 'white' } }} /></Grid>
                <Grid item xs={6}><TextField type="number" required name="clientPhone" onChange={handleChange} InputLabelProps={{ shrink: false }} variant="outlined" label={formData.clientPhone == '' ? 'Teléfono' : ''} size={isMobile ? "small" : "normal"} fullWidth InputProps={{ style: { backgroundColor: 'white' } }} /></Grid>
                <Grid item xs={6}><TextField required name="clientEmail" onChange={handleChange} InputLabelProps={{ shrink: false }} variant="outlined" label={formData.clientEmail == '' ? 'E-Mail' : ''} size={isMobile ? "small" : "normal"} fullWidth InputProps={{ style: { backgroundColor: 'white' } }} /></Grid>
                    <Grid item xs={12}>
                        <GooglePlacesAutocomplete
                        apiKey="AIzaSyCMWj2DWl4Gsx0J23jAtx-ix_FjzogcJmU"
                        selectProps={{
                            isClearable: true,
                            value: address,
                            onChange: (val) => {
                                setAddress(val);
                            },
                            placeholder: 'Dirección de la vivienda *',
                            styles: {
                                input: (provided) => ({
                                    ...provided,
                                    padding: isMobile ? '6px 1px' : '12px 1px',
                                })
                            }
                        }}
                        />
                </Grid>
                
                <Grid item xs={12} md={8}>
                        <FormGroup>
                        <FormControlLabel style={{ color: isDark ? 'white' : 'black' }} control={<Checkbox sx={{ color: isDark ? '#ffc022' : 'black' }} checked={legal} onChange={(e) => { setLegal(e.target.checked) }} />} label="Acepto la política de tratamiento de datos y privacidad" />
                    </FormGroup>
                    {alertError &&
                        <Alert severity="error">Se requieren los siguientes datos: {alertError}</Alert>
                    }
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button disableElevation variant="outlined" fullWidth style={{ padding: isMobile ? '5px 0px' : '12px 0px', backgroundColor: isDark ? '#ffc022' : 'white', color: isDark ? 'black' : 'black' }} onClick={sendData}>Solicitar oferta</Button>
                </Grid>
            </Grid>
            }
            {stepTwo == 2 &&
            <Grid container spacing={2} padding={2}>
                <Grid item xs={12}>
                    <h1 style={{ fontSize: isMobile ? '26px' : '30px', color: isDark ?'#ffc022':'black' }}>Sólo un paso más...</h1>
                    <h3 style={{ fontSize: isMobile ? '15px' : '23px', color: isDark ? 'white' : 'black', marginTop: '3px' }}>Adjunta tu última factura de electricidad y podremos darte un presupuesto mucho más preciso</h3></Grid>
                <Grid item xs={12}>
                    <FileUploader
                        classes="draganddrop"
                        children={<Box sx={{ width: '100%', padding: '90px 0', border: isDark ? '2px dashed white' : '2px dashed gray', cursor: 'pointer' }}><center><PhotoCamera sx={{ color: isDark ? 'white' : 'black' }} fontSize="large" />
                            <br />
                            <span style={{ color: isDark? 'white': 'black'}}>Haz una foto con tu móvil o adjunta tu factura descargada</span>
                        </center></Box>}
                        multiple={false}
                        handleChange={uploadInvoice}
                        name="file"
                        types={["JPG", "PDF", "JPEG", "PNG", "TIF", "TIFF", "WEBP"]}
                        label="Haz una foto con tu móvil o adjunta tu factura descargada"
                    /><center><br /><Button onClick={() => { setStepTwo(3) }}>No dispongo de factura</Button></center>
                </Grid>
            </Grid>
            }
            {stepTwo == 3 &&
                <Grid container spacing={2} padding={2}>
                    <Grid item xs={12}>
                    <h1 style={{ fontSize: isMobile ? '26px' : '30px', color: isDark ? '#ffc022' : 'black' }}>Sólo un paso más  (opcional)...</h1>
                        <h3 style={{ fontSize: isMobile ? '15px' : '23px', color: isDark ? 'white' : 'black', marginTop: '3px' }}>Aun sin la factura podemos ser precisos en nuestro cálculo con la siguiente información</h3>
                    </Grid>
                <Grid item xs={6}><TextField name="clientInvAmount" onChange={handleChange} InputLabelProps={{ shrink: false }} variant="outlined" label={formData.clientInvAmount == '' ? 'Importe medio de factura de luz' : ''} size={isMobile ? "small" : "normal"} fullWidth InputProps={{ style: { backgroundColor: 'white' } }} /></Grid>
                <Grid item xs={6}><TextField name="clientCups" onChange={handleChange} InputLabelProps={{ shrink: false }} variant="outlined" label={formData.clientCups == '' ? 'Código Cups (aparece en factura o contrato)' : ''} size={isMobile ? "small" : "normal"} fullWidth InputProps={{ style: { backgroundColor: 'white' } }} /></Grid>
                <Grid item xs={6}><TextField name="clientPower" onChange={handleChange} InputLabelProps={{ shrink: false }} variant="outlined" label={formData.clientPower == '' ? 'Potencia contratada' : ''} size={isMobile ? "small" : "normal"} fullWidth InputProps={{ style: { backgroundColor: 'white' } }} /></Grid>
                <Grid item xs={6}><TextField name="clientCompany" onChange={handleChange} InputLabelProps={{ shrink: false }} variant="outlined" label={formData.clientCompany == '' ? 'Compañía actual de suministro' : ''} size={isMobile ? "small" : "normal"} fullWidth InputProps={{ style: { backgroundColor: 'white' } }} /></Grid>
                <Grid item xs={12} md={8}>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button disableElevation disabled={formData.clientInvAmount === '' && formData.clientCups === '' && formData.clientPower === '' && formData.clientCompany === '' ? true : false} variant="outlined" fullWidth style={{ padding: isMobile ? '5px 0px' : '12px 0px', backgroundColor: isDark ? '#ffc022' : 'white', color: isDark ? 'black' : 'black' }} onClick={sendExtradata}>Solicitar oferta</Button>
                </Grid>
                </Grid>
            }
            {stepTwo == 4 &&
                <Grid container spacing={2} padding={2}>
                    <Grid item xs={12} style={{ padding: '150px 0' }}>
                        <center>
                            <h1 style={{ fontSize: isMobile ? '26px' : '30px', color: isDark ? '#ffc022' : 'black' }}>Hemos recibido correctamente tu solicitud</h1>
                            <h3 style={{ fontSize: isMobile ? '15px' : '23px', color: isDark ? 'white' : 'black', marginTop: '3px' }}>En breve nos pondremos en contacto contigo</h3>
                        </center>
                    </Grid>
                </Grid>
            }
    </div>
  );
};

export default Public;
