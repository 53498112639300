import React, { useState } from 'react';
import Logo from '@assets/images/logoLogin.png';
import { useForm } from '@hooks/useForm';
import { Box, Button, TextField, Typography } from '@mui/material';
import serviceLogin from '@services/serviceLogin';
import { useStyles } from '@styles/login.style';

import { useNavigate } from 'react-router-dom';

const NewPassword = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  

  const [error, setError] = useState('');
  const { userRecoveryHash, userPassword, userPasswordConfirm, handleChange } =
    useForm({
      userRecoveryHash: '',
      userPassword: '',
      userPasswordConfirm: '',
    });

  const handleSubmit = e => {
    e.preventDefault();
    if (!userRecoveryHash || !userPassword) {
      setError("Debe rellenar todos los campos");
      return;
    }
    serviceLogin
      .setNewPassword({ userRecoveryHash, userPassword })
      .then(response => {
        if (!response.error) {
          navigate('/', { replace: true });
        }
      });
  };

  return (
    <div className={classes.login}>
      <form onSubmit={handleSubmit} className={classes.card}>
        <div className={classes.header}>
            <img src={Logo} alt='logo' className={classes.logo} ></img>
        </div>
        <div>
          <Typography color='secondary' align='left' mt={3} fontSize={14}>
            Introduce el código de recuperación
          </Typography>
          <TextField
            name='userRecoveryHash'
                      label="Codigo de recuperación"
            variant='filled'
            fullWidth
            value={userRecoveryHash}
            onChange={handleChange}
            margin='dense'
            size='small'
          />
          <Typography color='secondary' align='left' mt={3} fontSize={14}>
            Introduce nueva contraseña
          </Typography>
          <TextField
            name='userPassword'
            label="Nueva contraseña"
            variant='filled'
            type='password'
            fullWidth
            value={userPassword}
            onChange={handleChange}
            margin='dense'
            size='small'
          />

          <TextField
            name='userPasswordConfirm'
                      label="Confirmar contraseña"
            variant='filled'
            type='password'
            fullWidth
            value={userPasswordConfirm}
            onChange={handleChange}
            margin='dense'
            size='small'
          />
        </div>
        {error && (
          <Typography align='center' color='error' fontSize={14} mt={3}>
            {error}
          </Typography>
        )}
        <Box mt={3}>
          <Button variant='contained' fullWidth type='submit' size='large'>
            GUARDAR CONTRASEÑA
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default NewPassword;
