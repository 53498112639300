import apiRequest from '@helpers/apiRequest';

const getRequestsHistory = () => {
    return apiRequest('GET', `RequestsHistory/List`);
};

const getRequestHistoryDetails = idRequestHistory => {
    return apiRequest('GET', `RequestsHistory/Details/${idRequestHistory}`);
};

const updateRequestHistory = data => {
    const idRequestHistory = data.idRequestHistory;
    return apiRequest('PUT', `RequestsHistory/Update/${idRequestHistory}`, data);
};

const newRequestHistory = data => {
    return apiRequest('POST', 'RequestsHistory/New', data);
};

const deleteRequestHistory = data => {
    const idRequestHistory = data.idRequestHistory;
    return apiRequest('DELETE', `RequestsHistory/Delete/${idRequestHistory}`);
};
const serviceRequestsHistory = {
    getRequestsHistory,
    getRequestHistoryDetails,
    updateRequestHistory,
    newRequestHistory,
    deleteRequestHistory,
};

export default serviceRequestsHistory;