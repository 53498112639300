import React from 'react';
import Loading from '@components/Loading';
import { useStyles } from '@styles/login.style';
import SignIn from './SignIn';

const LoginPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.login}>
      <SignIn />
    </div>
  );
};

export default LoginPage;
