import React, { useState, useEffect } from 'react';
import Avatar from '@components/Avatar';
import Dialog from '@components/Dialog';
import FormActions from '@components/FormActions';
import HeaderTitle from '@components/HeaderTitle';
import { useForm } from '@hooks/useForm';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from '@mui/material';
import serviceUsers from '@services/serviceUsers';
import serviceList from '@services/serviceList';
import { useCommonStyles } from '@styles/common.style';
import { useLocation, useNavigate } from 'react-router-dom';


const UserDetail = () => {
  
  const navigate = useNavigate();
  const classes = useCommonStyles();
  const {
    state: { data: USER_DATA, type: TYPE },
  } = useLocation();

  const [openDialog, setOpenDialog] = useState(false);
  const { formData, setFormData, handleChange } = useForm(USER_DATA);

  const HEADER_TITLE =
    TYPE === 'NEW' ? 'Nuevo usuario' : 'Editar usuario';
  const HEADER_SUBTITLE =
      TYPE === 'NEW' ? 'Crea un nuevo usuario para usar la aplicación' : 'Puedes editar, eliminar y actualizar los datos del usuario';

  const disabledSaveButton = USER_DATA === formData;
  const [roles, setRoles] = useState(null);

  useEffect(() => {
    serviceList.getRolesList().then(response => {
      if (!response.error) {
        setRoles(response.data);
      }
    });
  }, []);

  const handleSubmit = type => {
    switch (type) {
      case 'NEW':
        serviceUsers.newUser(formData).then(handleResponse);
        break;
      case 'UPDATE':
        serviceUsers.updateUser(formData).then(handleResponse);
        break;
      case 'DELETE':
        serviceUsers.deleteUser(formData).then(handleResponse);
        break;
      default:
        break;
    }
  };

  const handleResponse = response => {
    if (!response.error) {
      navigate('/users');
    }
  };

  const handleChangeImage = e => {
    const image = e.base64.replace('data:' + e.type + ';base64,', '');
    setFormData(prevState => {
      return { ...prevState, userImage: image };
    });
  };

  return (
    <div className={classes.container}>
      <Dialog
        open={openDialog}
        hasCloseBtn={false}
        close={() => setOpenDialog(false)}
        title='¿Seguro que quieres eliminar el usuario?'
        subtitle='Si lo eliminas no podrás recuperarlo'
        height={200}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
                color='error'>
                Cancelar
              </Button>
            </Box>
            <Button variant='contained' onClick={() => handleSubmit('DELETE')}>
              Borrar
            </Button>
          </Box>
        }
      />

      <HeaderTitle title={HEADER_TITLE} subtitle={HEADER_SUBTITLE}>
        <FormActions
          show={TYPE === 'UPDATE'}
          handleDelete={() => setOpenDialog(true)}
          handleUndo={() => setFormData(USER_DATA)}
          handleSave={() => handleSubmit(TYPE)}
          disabledButton={disabledSaveButton}
        />
      </HeaderTitle>

      <Grid container spacing={2}>
              <Grid item lg={3} xs={12} sm={6} mt={2}>
          <Typography color='primary' variant='subtitle1' mb={2}>
            Imagen del usuario
          </Typography>
          <Box mt={2}>
            <Avatar
              height={'100%'}
              handleChange={handleChangeImage}
              image={formData?.userImage}
            />
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          xs={12}
                  sm={6}
                  mt={2}
        >
          <Typography color='primary' variant='subtitle1'>
            Datos personales
          </Typography>
          <Box>
            <TextField
              label='Nombre del usuario'
              name='userName'
              value={formData.userName || ''}
              size='small'
              onChange={handleChange}
              fullWidth
              margin='normal'
            />
            <TextField
              label='Apellidos del usuario'
              name='userSurname'
              value={formData.userSurname || ''}
              size='small'
              onChange={handleChange}
              fullWidth
              margin='normal'
            />
            <TextField
              label='Dirección de e-mail'
              name='userEmail'
              value={formData.userEmail || ''}
              size='small'
              onChange={handleChange}
              fullWidth
              margin='normal'
            />

            <FormControl fullWidth size='small' margin='normal'>
              <InputLabel id='rol'>Rol</InputLabel>
              <Select
                labelId='rol'
                label='Rol'
                value={formData.idRole || 1}
                defaultValue="1"
                onChange={handleChange}
                name='idRole'>
                {roles?.map(rol => (
                  <MenuItem value={rol.idRole} key={rol.idRole}>
                    {rol.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserDetail;
