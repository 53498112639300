import { makeStyles } from '@mui/styles';

export const useCommonStyles = makeStyles(theme => {
  return {
    container: {
      padding: '25px 40px',
      [theme.breakpoints.down('sm')]: {
        padding: '0px',
      },
    },
    overflowX: {
      overflowX: 'auto',
    },

    bgPrimary: {
        background: '#2d3e50',
    },

    bgGrey: {
      background: theme.palette.grey.main,
      },

      bgDarkGrey: {
          background: '#cfcfcf',
      },
      hideOnMobile: {
          [theme.breakpoints.down('sm')]: {
              display: 'none'
          }
      },
      showOnMobile: {
          [theme.breakpoints.up('sm')]: {
              display: 'none'
          }
      },
  };
});
