import apiRequest from '@helpers/apiRequest';

const getRequestDetails = idRequest => {
  return apiRequest('GET', `Requests/Details/${idRequest}`);
};

const SyncGSheet = idRequest => {
    return apiRequest('GET', `Requests/SyncGSheet/${idRequest}`);
};

const getRequestFiles= idRequest => {
    return apiRequest('GET', `Requests/Files/${idRequest}`);
};

const postRequestFile = (idRequest,data) => {
    return apiRequest('POST', `Requests/UploadFile/${idRequest}`, data);
};

const deleteRequestFile = (idRequest, data) => {
    return apiRequest('DELETE', `Requests/DeleteFile/${idRequest}`, data);
};

const downloadRequestFile = (idRequest, data) => {
    return apiRequest('POST', `Requests/DownloadFile/${idRequest}`, data);
};

const getRequests = () => {
    return apiRequest('GET', `Requests/GetList`);
};

const getRequestsStatus = () => {
    return apiRequest('GET', `Requests/Status`);
};

const updateRequest = data => {
  const idRequest = data.idRequest;
  return apiRequest('PUT', `Requests/Update/${idRequest}`, data);
};

const newPublicRequest = data => {
    return apiRequest('POST', 'Requests/PublicNew', data);
};

const postPublicUpdate = data => {
    return apiRequest('POST', 'Requests/PublicUpdate', data);
};

const postRequestPublicFile = (idRequest, data) => {
    return apiRequest('POST', `Requests/PublicUploadFile/${idRequest}`, data);
};

const newRequest = data => {
  return apiRequest('POST', 'Requests/New', data);
};

const deleteRequest = data => {
  const idRequest = data.idRequest;
  return apiRequest('DELETE', `Requests/Delete/${idRequest}`);
};
const serviceRequests = {
    SyncGSheet,
    downloadRequestFile,
    deleteRequestFile,
    postRequestFile,
    getRequestFiles,
    getRequestsStatus,
    getRequests,
  getRequestDetails,
  updateRequest,
    newRequest,
    newPublicRequest,
    deleteRequest,
    postRequestPublicFile,
    postPublicUpdate
};

export default serviceRequests;
