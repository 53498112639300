import React, { useContext } from 'react';
import Logo from '@assets/images/logoLogin.png';
import { AuthContext } from '@context/auth/AuthContext';
import { useForm } from '@hooks/useForm';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import serviceLogin from '@services/serviceLogin';
import { useStyles } from '@styles/login.style';

import { Link as RouterLink } from 'react-router-dom';

const SignIn = ({ flagOptions }) => {
  const classes = useStyles();
  const { login } = useContext(AuthContext);

  const { formData, loginPassword, loginEmail, handleChange } = useForm({
    loginEmail: '',
    loginPassword: '',
  });

  const handleSubmit = e => {
    e.preventDefault();
    serviceLogin.postSignIn(formData).then(response => {
      const data = response.data;
      if (!response.error) {
        login(data);
      }
    });
  };

  return (
    <form onSubmit={handleSubmit} className={classes.card}>
      <div className={classes.header}>
        <img src={Logo} alt='logo' className={classes.logo} ></img>
        <Typography className={classes.title}>Acceso a la gestión de presupuestos</Typography>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} mb={1}>
          <TextField
            id='loginEmail'
            label="E-Mail"
            variant='filled'
            name='loginEmail'
            type='text'
            fullWidth
            value={loginEmail}
            onChange={handleChange}
            size='small'
          />
        </Grid>

        <Grid item xs={12} mb={1}>
          <TextField
            id='loginPassword'
            label="Contraseña"
            variant='filled'
            name='loginPassword'
            type='password'
            fullWidth
            value={loginPassword}
            onChange={handleChange}
            autoComplete='off'
            size='small'
          />
        </Grid>
      </Grid>
      <Box mt={3}>
        <Button variant='contained' fullWidth type='submit' size='large'>
          ACCEDER AL GESTOR
        </Button>

        <Box
          mt={2}
          display='flex'
          sx={{
            justifyContent: {
              xs: 'center',
              sm: 'end',
            },
          }}>
          <Typography
            component={RouterLink}
            className={classes.subtitle}
            to='/lostPassword'
            color='secondary'
            fontSize={14}
            align='center'>
            ¿Has olvidado la contraseña?
          </Typography>
        </Box>
      </Box>
    </form>
  );
};

export default SignIn;
