import apiRequest from '@helpers/apiRequest';


const getRolesList = data => {
  return apiRequest('GET', 'Roles/ListRoles');
};
const serviceList = {
  getRolesList,
};

export default serviceList;
