import React from "react";

import { useStyles } from "@styles/menu.style";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";

const MenuList = ({ menuTitle, items, handleClick }) => {
  const classes = useStyles();
  return (
    <List dense disablePadding>
      <ListSubheader
        disableSticky
        component="div"
        id="list"
        className={classes.listTitle}
      >
        {menuTitle}
      </ListSubheader>
      {items.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemButton onClick={() => handleClick(item)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default MenuList;
