import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MUIDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
const Dialog = ({
  children,
  title,
  subtitle,
  handleOpen,
  handleClose,
  handleOk,
  actions,
}) => {
  return (
    <MUIDialog disableEscapeKeyDown open={handleOpen} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText align='center'>{subtitle}</DialogContentText>
        {children && <Box py={1}>{children}</Box>}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose} color='error'>
          Cancelar
        </Button>
        <Button variant='contained' onClick={handleOk}>
          Ok
        </Button>
      </DialogActions>
    </MUIDialog>
  );
};

export default Dialog;
