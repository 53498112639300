import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from '@context/auth/AuthContext';
import customTheme from '@assets/theme';
import { ThemeProvider } from '@mui/material';
import { StylesProvider, jssPreset } from '@mui/styles';
import { create } from 'jss';
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('root'),
});

ReactDOM.render(
  <StylesProvider jss={jss}>
    <ThemeProvider theme={customTheme}>
      <AuthProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </AuthProvider>
    </ThemeProvider>
  </StylesProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
