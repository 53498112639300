import Loading from '@components/Loading';
import { AuthContext } from '@context/auth/AuthContext';

import { SnackbarProvider } from 'notistack';
import React, { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import './App.css';
import { SnackbarUtilsConfigurator } from './common/helpers/snackbarUtils';
import './i18n/config';

/* Routes */
import LoginPage from '@pages/login/LoginPage';
import LostPassword from '@pages/login/LostPassword';
import NewPassword from '@pages/login/NewPassword';
import Home from '@pages/home/Home';
import Requests from '@pages/requests/Requests';
import RequestDetail from '@pages/requests/RequestDetail';
import Users from '@pages/users/Users';
import UserDetail from '@pages/users/UserDetail';
import Providers from '@pages/providers/Providers';
import ProviderDetail from '@pages/providers/ProviderDetail';
import Payments from '@pages/payments/Payments';
import PaymentDetail from '@pages/payments/PaymentDetail';
import Origins from '@pages/origins/Origins';
import OriginDetail from '@pages/origins/OriginDetail';
import Mailings from '@pages/mailing/Mailings';
import MailingDetail from '@pages/mailing/MailingDetail';
import Public from '@pages/public/Public';
import Configs from '@pages/configs/Configs';
import ConfigDetail from '@pages/configs/ConfigDetail';
import Google from '@pages/google/Google';
/* End Routes */

const PrivateRoute = () => {
  const { status } = useContext(AuthContext);
  return status === 'authenticated' ? <Home /> : <Navigate to='/' />;
};

function App() {
  const { status } = useContext(AuthContext);

  if (status === 'checking') return <Loading />;

  return (
    <SnackbarProvider maxSnack={2}>
      <SnackbarUtilsConfigurator />
      <Router>
         <Routes>
          <Route
            path='/'
            element={
              status === 'authenticated' ? (
                    <Navigate to='/requests/1' />
              ) : (
                <LoginPage />
              )
            }
          />
          <Route path='*' element={<Navigate to='/' />} />
          <Route path='/lostPassword' element={<LostPassword />} />
          <Route path='newPassword' element={<NewPassword />} />
          <Route path='/reset' element={<NewPassword />} />
                  <Route path='/public/:idOrigin' element={<Public />} />
                  <Route path='/public/:idOrigin/:theme' element={<Public />} />
                  <Route path='/commercial/:idUser' element={<Public />} />
          <Route element={<PrivateRoute />}>
                      <Route path='requests/:idRequestStatus' element={<Requests key={0} />} />
                      <Route path='requestsgrants/:idRequestGrantStatus' element={<Requests key={1} />} />
                      <Route path='requestDetail' element={<RequestDetail />} />
                      <Route path='providers' element={<Providers />} />
                      <Route path='providerDetail' element={<ProviderDetail />} />
                      <Route path='users' element={<Users />} />
                      <Route path='userDetail' element={<UserDetail />} />
                      <Route path='payments' element={<Payments />} />
                      <Route path='paymentDetail' element={<PaymentDetail />} />
                      <Route path='origins' element={<Origins />} />
                      <Route path='originDetail' element={<OriginDetail />} />
                      <Route path='mailings' element={<Mailings />} />
                      <Route path='mailingDetail' element={<MailingDetail />} />
                      <Route path='configs' element={<Configs />} />
                      <Route path='configDetail' element={<ConfigDetail />} />
                      <Route path='google' element={<Google />} />
                  </Route>
        </Routes>
      </Router>
    </SnackbarProvider>
  );
}

export default App;
